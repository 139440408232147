/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
// import style from "../Styles/tableFilter.module.css";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Vocabulary } from "../Utils/Vocabulary";
import { ThemeProvider } from "@emotion/react";
import { getMuiTheme } from "../Utils/globalStyleTables";
import SaveIcon from "@mui/icons-material/Save";
import { styled } from "@mui/material/styles";
import TableSearch from "../Utils/TableSearch";
import WarningIcon from "@mui/icons-material/Warning";
import { isMobile } from "../Utils/utils";

// import withRole from "../Utils/withRole";

type DinamicTabsProps = {
  name?: string;
  labels?: any;
  handleChangeTab?: any;
  value?: any;
  addNewLabel?: any;
  openModalWindow?: any;
  search?: boolean;
  hideSave?: any;
  hideAddNew?: any;
  saveIdButton?: any;
  info?: any;
  addNewButtonFromRoles?: any;
  warningMessage?: any;
  shortWarningMessage?: any;
};

export default function DinamicTabs(props: DinamicTabsProps) {
  // const { addNewButtonFromRoles } = props;
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      top: isMobile() ? -1 : -14,
      left: isMobile() ? "50%" : 9,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
      color: "#fff",
    },
  }));

  /**
   *
   * @param item
   * @returns
   */
  const getCustomMarginForNavigation: any = (item: number) => {
    switch (item) {
      case 5:
        return 40;

      case 6:
        return 80;
      case 9:
        return 100;

      default:
        return "auto";
    }
  };

  /**
   *
   * @param item
   * @returns
   */
  const getCustomWidthForNavigation: any = (item: number) => {
    switch (item) {
      case 5:
        return "105%";

      case 6:
        return "105%";
      case 9:
        return "145%";

      default:
        return "100%";
    }
  };

  return (
    <div>
      <ThemeProvider theme={getMuiTheme()}>
        {isMobile() ? (
          <div>
            {props.search ? (
              <div>
                <TableSearch></TableSearch>
              </div>
            ) : null}
            <Paper
              sx={{
                position: "fixed",
                bottom: 0,
                left: -20,
                right: -20,
                zIndex: 200,

                overflowX: "scroll",
              }}
              elevation={5}
            >
              {props.shortWarningMessage ? (
                <div
                  style={{
                    padding: 20,
                    marginRight: 40,
                    color: "#f33",
                    marginBottom: -5,
                    width: 100,
                    position: "fixed",
                    bottom: 35,
                    left: -10,
                  }}
                >
                  <div
                    style={{
                      justifyContent: "center",
                      textAlign: "center",
                      backgroundColor: "#ffe43399",
                      padding: 10,
                      marginTop: -20,
                      display: "flex",
                      flexDirection: "row",
                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    <Typography style={{ fontWeight: 900 }}>
                      {props.shortWarningMessage}
                    </Typography>
                  </div>
                </div>
              ) : null}
              <BottomNavigation
                showLabels
                value={props.value}
                style={{
                  width: getCustomWidthForNavigation(props.labels.length),
                  backgroundColor: "rgba(247,248,250,1)",
                  marginLeft: getCustomMarginForNavigation(props.labels.length),
                  marginRight: getCustomMarginForNavigation(
                    props.labels.length
                  ),
                }}
                onChange={props.handleChangeTab}
              >
                {props.labels.map((tab: any, index: number) => (
                  <BottomNavigationAction
                    key={index}
                    sx={{ fontWeight: 800 }}
                    label={tab.label ? tab.label : tab}
                  />
                ))}

                {props.hideSave && props.addNewLabel ? (
                  <Button
                    style={{ borderRadius: 0 }}
                    size="small"
                    type="submit"
                    form={props.saveIdButton}
                  >
                    <SaveIcon
                    // onClick={() => props.openModalWindow(true)}
                    ></SaveIcon>
                  </Button>
                ) : null}
                {(props.addNewLabel || props.addNewButtonFromRoles) &&
                !props.hideAddNew ? (
                  <Button
                    style={{ borderRadius: 0, marginRight: 10 }}
                    size="small"
                  >
                    <AddCircleIcon
                      onClick={() => props.openModalWindow(true)}
                    ></AddCircleIcon>
                  </Button>
                ) : null}
              </BottomNavigation>
            </Paper>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "normal" }}>
            <Tabs
              value={props.value}
              onChange={props.handleChangeTab}
              textColor="inherit"
              className="tabs-container"
              aria-label="basic tabs example"
              TabIndicatorProps={{
                style: {
                  background: "#FF6633",
                  color: "white",
                  overflow: "visible",
                },
              }}
              style={{
                marginTop: 10,
              }}
            >
              {props.labels[props.labels.length - 1] === ""
                ? null
                : props.labels.map((tab: any, index: number) => (
                    <Tab
                      key={index}
                      style={{
                        marginRight: 1,
                        marginLeft: 1,
                        display: tab === "null" ? "none" : "auto",
                        backgroundColor:
                          props.labels[1] === "" ? "#FF6633" : "#4444",
                        color: props.labels[1] === "" ? "#fff" : "inherit",
                        overflow: "visible",
                        borderRadius:
                          props.labels[1] === ""
                            ? "0px 0px 0px 0px"
                            : "10px 10px 0px 0px",
                        fontWeight: props.labels[1] === "" ? 600 : "inherit",
                      }}
                      label={
                        <StyledBadge
                          max={999}
                          showZero={false}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          badgeContent={tab?.badge ? tab?.badge : 0}
                          color="primary"
                        >
                          {tab?.label ? tab?.label : tab}
                        </StyledBadge>
                      }
                      className="tab"
                    ></Tab>
                  ))}
            </Tabs>
            {props.warningMessage ? (
              <div
                style={{
                  padding: "30px 20px ",
                  marginRight: 40,
                  color: "#f33",
                  marginBottom: -30,
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ffe43399",
                    padding: 10,
                    marginTop: -20,
                    display: "flex",
                    flexDirection: "row",
                    borderRadius: 20,
                  }}
                >
                  <WarningIcon
                    style={{ marginRight: 10, marginTop: -3 }}
                  ></WarningIcon>
                  <Typography style={{ fontWeight: 900 }}>
                    {props.warningMessage}
                  </Typography>
                </div>
              </div>
            ) : null}
            <div
              id="addNew_Button"
              style={{
                display: "flex",
                position: "absolute",
                top: props.addNewLabel === Vocabulary.addNewVehicle ? 80 : 80,
                right: props.addNewLabel === Vocabulary.addNewVehicle ? 60 : 40,
              }}
            >
              {props.search ? (
                <div>
                  <TableSearch></TableSearch>
                </div>
              ) : null}
              <>
                {props.hideSave && props.addNewLabel ? (
                  <Button
                    // onClick={() => props.openModalWindow(true)}
                    startIcon={<SaveIcon />}
                    variant="contained"
                    type="submit"
                    form={props.saveIdButton}
                    style={{
                      color: "white",
                      padding: "10px 15px 10px 15px",
                      fontWeight: 600,
                      zIndex: 0,
                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    {Vocabulary.save}
                  </Button>
                ) : null}
                {!props.hideAddNew && props.addNewLabel ? (
                  <Button
                    onClick={() => props.openModalWindow(true)}
                    startIcon={<AddCircleIcon />}
                    variant="contained"
                    style={{
                      color: "white",
                      padding: "10px 15px 10px 15px",
                      fontWeight: 600,
                      borderRadius: "10px 10px 0px 0px",
                    }}
                  >
                    {props.addNewLabel}
                  </Button>
                ) : null}
                {props.addNewButtonFromRoles ? (
                  <Button
                    onClick={() => props.openModalWindow(true)}
                    startIcon={<AddCircleIcon />}
                    variant="contained"
                    style={{
                      color: "white",
                      padding: "10px 15px 10px 15px",
                      fontWeight: 600,
                      borderRadius: "10px 10px 0px 0px",
                      // marginTop: -17,
                    }}
                  >
                    {Vocabulary.addNewVehicle}
                  </Button>
                ) : null}
              </>
            </div>
          </div>
        )}
      </ThemeProvider>
    </div>
  );
}
