/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import PreviewPDF from "../Components/Generic/PDFViewer";
import PreviewImage from "../Components/Generic/PreviewImage";
import { getFileExtensionByFileType } from "./utils";
import { useLocation } from "react-router-dom";
import DownloadDoc from "../Components/VehicleDasboard/DownloadDoc";

export type FileDataInfo = {
  name: string | undefined;
  path: string | undefined;
  type: string | null;
};

//add events listeners timeout
const timeout = 500;

export default function FilePreview() {
  const [fileData, setFileData] = useState<FileDataInfo | null>(null);
  const location = useLocation();
  const [reload, setReload] = useState(0);
  const [reloadFilePreviewBecauseISetDataFile, setReloadFilePreviewBecauseISetDataFile] = useState<any>([]);

  useEffect(() => {
    // this is here so we do not need to reload the window after generating pdf file (proforma was the example that did not work)
    const nodes: any = document.querySelectorAll("*[data-file]");
    nodes.forEach((element: any) => {
      const fileName  = element?.dataset.file;
      if (!reloadFilePreviewBecauseISetDataFile.includes(fileName))
        setReloadFilePreviewBecauseISetDataFile([...reloadFilePreviewBecauseISetDataFile, fileName]);

    });
  }, [document.querySelectorAll("*[data-file]")]);

  useEffect(() => {
    window.addEventListener("load", addEventHandlers);
    setReload(Math.floor(Math.random() * 100));
    return () => {
      removeEventHandlers();
    };
  }, [location, reloadFilePreviewBecauseISetDataFile]);

  /**
   *
   */
  useEffect(() => {
    const globalLoading = document.querySelector("#loading");
    if (
      globalLoading &&
      globalLoading.classList.contains("loadingDisabled") &&
      document.querySelectorAll("*[data-file]").length
    ) {
      setTimeout(addEventHandlers, timeout);
    } else {
      setTimeout(() => setReload(reload + 1), timeout);
    }
    return () => {
      removeEventHandlers();
    };
  }, [reload]);

  /**
   *
   * @returns
   */
  function removeEventHandlers() {
    const domFiles = document.querySelectorAll("*[data-file]");
    if (domFiles && domFiles.length) {
      domFiles.forEach((domFile) => {
        domFile.removeEventListener("click", domElementClick);
      });
    }
  }

  /**
   *
   */
  function addEventHandlers() {
    const loadingActive = document.getElementById("loading")?.classList.length;
    if (!loadingActive) {
      setTimeout(addEventHandlers, 500);
      return;
    }
    const domFiles = document.querySelectorAll("*[data-file]");
    if (domFiles && domFiles.length) {
      domFiles.forEach((domFile) => {
        domFile.removeEventListener("click", domElementClick);
        domFile.addEventListener("click", domElementClick);
      });
    }
  }

  /**
   *
   * @param e
   * @returns
   */
  function domElementClick(e: any) {
    e.preventDefault();
    const file = (e.currentTarget as HTMLElement)?.getAttribute("data-file");
    if (file) {
      setFileData({
        path: file,
        name: file?.split("/").pop(),
        type: getFileExtensionByFileType(file) || "",
      });
    }
    return false;
  }

  if (!fileData) return null;

  if (fileData.type === "pdf" || fileData.type === "PDF")
    return (
      <PreviewPDF
        title={fileData?.name || ""}
        open={fileData ? true : false}
        onClose={() => {
          setFileData(null);
        }}
        url={fileData?.path}
      />
    );

  if (
    fileData.type === "jpg" ||
    fileData.type === "png" ||
    fileData.type === "jpeg" ||
    fileData.type === "webp" ||
    fileData.type === "gif" ||
    fileData.type === "svg"
  )
    return (
      <PreviewImage
        title={fileData?.name}
        open={fileData ? true : false}
        onClose={() => {
          setFileData(null);
        }}
        url={fileData?.path}
      />
    );

  if (
    fileData.type === "docx" ||
    fileData.type === "doc" ||
    fileData.type === "xlsx" ||
    fileData.type === "xls"
  )
    return <DownloadDoc fileData={fileData} />;
  return null;
}
