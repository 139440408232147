/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import MenuIcon from "@mui/icons-material/Menu";
import {
  CircularProgress,
  Fab,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useEffect, useRef, useState } from "react";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import style from "../Styles/mainHeader.module.css";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
// import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { postData } from "../Services/postData";
import { localUrlEnum, urlEnum } from "../Utils/urlEnum";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { disableTostifyConfig, isMobile } from "../Utils/utils";
import Notifications from "../Notifications/Notifications";
import { getData } from "../Services/getData";
import { VehicleModel } from "../Models/VehicleModel";
import { Vocabulary } from "../Utils/Vocabulary";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import useNoActivity from "../Hooks/useNoActivity";
import { INACTIVITY_TIMEOUT } from "../Utils/Constants";
import withRole from "../Utils/withRole";

const MIN_SEARCH_LEN = 2;
let logoutTimeout: any;

function MainHeader(props: any) {
  const username = localStorage.getItem("userName");
  const navigate = useNavigate();
  const [searchText, setSearchText]: any = useState("");
  const [searchResult, setSearchResult]: any = useState(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const hasActivity = useNoActivity();
  const abortControllerRef = useRef<AbortController | null>(null);
  const element = document.getElementById("loading-search");
  const open = Boolean(anchorEl);

  /**
   * Get authenticated user
   */
  useEffect(() => {
    const userId = localStorage.getItem("useId");
    (window as any).authenticatedUserIdAutodel = userId;
  }, []);

  /**
   * Handle search input change
   */
  useEffect(() => {
    if (searchText && searchText.length > MIN_SEARCH_LEN) {
      element?.classList.remove("loadingDisabled");
      element?.classList.add("loading");

      // Abort(cancel) previous request if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // Create a new AbortController for the new request
      const controller = new AbortController();
      abortControllerRef.current = controller;

      postData(
        `${urlEnum.searchVehicles}${disableTostifyConfig}`,
        { search: searchText },
        { signal: controller?.signal }
      )
        .catch((e: any) => {
          console.log(e);
        })
        .then((response) => {
          if (response?.data) {
            if (response?.data?.length == 0) removeLoading();
            setSearchResult(response?.data);
          }
        });
    } else {
      setSearchResult([]);
      removeLoading();
    }
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [searchText]);

  /**
   * Removes the loading animation only when the search result is not empty
   */
  useEffect(() => {
    if (searchResult?.length > 0) {
      removeLoading();
    }
  }, [searchResult]);

  /**
   * Removes the loading animation
   */
  const removeLoading = () => {
    element?.classList.remove("loading");
    element?.classList.add("loadingDisabled");
  };

  /**
   *
   */
  useEffect(() => {
    if (!hasActivity) {
      logoutTimeout = setTimeout(() => {
        handleLogout();
      }, INACTIVITY_TIMEOUT);
    } else {
      if (logoutTimeout) {
        clearTimeout(logoutTimeout);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasActivity]);

  /**
   *
   */
  const handleLogout = async () => {
    setAnchorEl(null);
    try {
      const response: any = await getData(urlEnum.logout);
      if (response && !response.errors) {
        logout();
        navigate(localUrlEnum.login);
      }
    } catch (e) {
      /* empty */
    }
  };

  /**
   * set anchor el for menu
   * @param event
   */
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * close menu
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * logout
   */
  function logout() {
    postData(`${urlEnum.logout}`).then(() => {
      setAnchorEl(null);
      localStorage.setItem("useId", "");
      localStorage.setItem("userName", "");
      localStorage.setItem("userEmail", "");
      localStorage.setItem("access_token", "");
      localStorage.setItem("cacheData", "");
      localStorage.setItem("permissions", "");
      localStorage.setItem("roles", "");
      document.cookie = "accept_notification =; ";
      navigate("/login");
      window.location.reload();
    });
  }

  /**
   * change search text
   * @param event
   */
  function onChangeSearch(event: any) {
    setSearchText(event.target.value);
  }

  /**
   * clear search text
   */
  function clearSearch() {
    setSearchText("");
  }

  /**
   * if the vehicle doesnt have a main image, it shows the first image of the vehicle
   */
  const renderVehicleImage = (vehicle: VehicleModel) => {
    let file = vehicle?.main_image;
    if (!file) {
      if (vehicle.vehicule_files_r?.length)
        file = vehicle.vehicule_files_r[0].file;
    }
    return file ? (
      <a href={`${localUrlEnum.vehicles}/${props.info}/${vehicle.id}`}>
        <div
          className={style.iconWrapper}
          style={{
            backgroundImage: `url("/api/${file}")`,
          }}
        />
      </a>
    ) : (
      <a href={`${localUrlEnum.vehicles}/${props.info}/${vehicle.id}`}>
        <div className={style.iconWrapper}>
          <DirectionsCarIcon />
        </div>
      </a>
    );
  };

  return (
    <div className={style.container}>
      <div style={{ display: "flex" }}>
        {isMobile() ? (
          <div>
            <IconButton
              onClick={props.handleFilter}
              style={{ backgroundColor: "#fff" }}
              aria-label="add"
            >
              {props.hideSlider ? <MenuIcon /> : <CloseIcon></CloseIcon>}
            </IconButton>
          </div>
        ) : (
          <img src="/logoAutodel.png" alt="No file" style={{ marginLeft: 5 }} />
        )}

        <div>
          <Typography className={style.title}>{props.title}</Typography>
        </div>
      </div>
      <div className={style.searchField}>
        <InputBase
          sx={{ ml: 3, flex: 2 }}
          placeholder="Cautare"
          value={searchText}
          inputProps={{ "aria-label": "Cauta" }}
          startAdornment={
            isMobile() ? (
              <img
                src="/logoAutodelSmall.png"
                alt="No file"
                height={23}
                style={{ margin: 3, marginLeft: -15 }}
              />
            ) : null
          }
          onChange={(event) => onChangeSearch(event)}
        />
        {searchText.length > 0 ? (
          <IconButton
            onClick={() => clearSearch()}
            type="button"
            sx={{ p: "6px" }}
            aria-label="search"
          >
            <CloseIcon />
          </IconButton>
        ) : (
          <IconButton type="button" sx={{ p: "6px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
        )}
      </div>

      {/** SEARCH RESULTS */}
      {searchText.length > MIN_SEARCH_LEN && (
        <div className={style.wrapper} id="searchWrapper">
          <Paper
            className={style.searchResults}
            style={{ display: "block", position: "relative" }}
          >
            <div className={style.searchWrapper}>
              <h3 style={{ fontWeight: 100 }}>{Vocabulary.searchResult}</h3>
            </div>
            <Divider />
            {searchResult && (
              <div
                style={{
                  overflowY: "scroll",
                  width: "100%",
                  height: "90%",
                  position: "absolute",
                }}
              >
                <div className={`${style.searchWrapper}`}>
                  <List sx={{ width: "90%", bgcolor: "background.paper" }}>
                    {searchResult.map(
                      (vehicle: VehicleModel, index: number) => (
                        <ListItem
                          alignItems="flex-start"
                          key={index}
                          className={style.searchListItem}
                        >
                          <ListItemAvatar
                            style={{ margin: "0px 20px 0px 0px", width: 76 }}
                          >
                            {renderVehicleImage(vehicle)}
                          </ListItemAvatar>
                          <a
                            className={style.linkFull}
                            href={`${localUrlEnum.vehicles}/${props.info}/${vehicle.id}`}
                          >
                            <ListItemText
                              primary={`${vehicle?.brand_r?.nume} ${vehicle?.model_r?.nume}`}
                              secondary={
                                <>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    VIN: {vehicle.vin.toUpperCase()}
                                  </Typography>
                                  <br />
                                  {Vocabulary.status}:{" "}
                                  {vehicle?.status_vehicul_r?.nume}
                                  <br />
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        backgroundColor: "#FF6638",
                                        color: "#fff",
                                        borderRadius: 2,
                                        padding: 2,
                                      }}
                                    >
                                      {Vocabulary.nume_firma}:{" "}
                                      {vehicle?.firma_achizitie_r?.nume_firma}
                                    </div>
                                  </div>
                                </>
                              }
                            />
                          </a>
                        </ListItem>
                      )
                    )}
                  </List>
                </div>
              </div>
            )}
          </Paper>
        </div>
      )}
      {/** END SEARCH RESULTS */}

      <div style={{ display: "flex" }}>
        <Typography className={style.username}>{username}</Typography>
        <Notifications />
        <Fab
          onClick={handleClick}
          size="small"
          style={{ backgroundColor: "#fff", color: "#4D5E80" }}
          aria-label="add"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <AccountCircleIcon />
        </Fab>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar /> {isMobile() ? username : Vocabulary.myAccount}
          </MenuItem>
          <Divider />

          {/* <MenuItem onClick={() => navigate("/settings")}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Setari
          </MenuItem> */}
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
        {/* am folosit un alt loading pt ca dand cancel la request-uri, automat se dadea remove la loading(cel folosit in general cu id-ul loading) */}
        <div
          id="loading-search"
          className={props.enabled ? "" : "loadingDisabled"}
        >
          <CircularProgress color="primary" />
        </div>
      </div>
    </div>
  );
}

export default withRole(MainHeader);
