/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid } from "@mui/material";

import { Vocabulary } from "../../../Utils/Vocabulary";
import { useContext, useEffect, useState } from "react";
import { VehicleContext } from "../../../Context/vehicleContext";

import GenericModal from "../../../Modal/GenericModal";
// import moment from "moment";
import { CacheContext } from "../../../Context/cacheContext";
import { VehiclesStatus } from "../../../Utils/autocompleteEnum";
import { urlEnum } from "../../../Utils/urlEnum";
import useResetContextState from "../../../Hooks/useResetContextState";
import ShippingModal from "../../Settings/ShippingModal";
import { postData } from "../../../Services/postData";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { europeanDate } from "../../../Utils/formatDate";
import moment from "moment";

type SubProgressInformation5_1Props = {
  disabled?: boolean;
  handleModalReserve: () => void;
  open: boolean;
  shippings: object[];
  refreshShippingList?: any;
};

export default function SubProgressInformation5_2(
  props: SubProgressInformation5_1Props
) {
  const vehicleContext: any = useContext(VehicleContext);
  const cacheContext = useContext(CacheContext) as any;
  const resetContextState = useResetContextState();
  const [openModalClient, setOpenModalClient] = useState(false);


  useEffect(() => {
    if(props.open === true){
      resetContextState.setInitialState();
    }
  }, [props.open]);
  /**
   *
   */
  const handleOpenCloseModal = () => {
    if (props.open) {
      resetContextState.resetState();
    }
    props.handleModalReserve && props.handleModalReserve();
  };

  /**
   *
   * @param param0
   */

  /**
   *
   */
  async function reserveVehicle() {
    const status = cacheContext.cache?.vehicleStatus?.find(
      (status: any) => status.nume === VehiclesStatus.inTransit
    );
    const localVehicleInfo = {
      ...vehicleContext.state.vehicle,
      indexes: vehicleContext.updateIndex(["0.2"], null),
    };
    const data = {
      vehicle: localVehicleInfo,
      vehicle_id: vehicleContext.state.vehicle.id,
      status_id: status.id,
      shipping_id:
        vehicleContext?.state?.vehicle?.vehicle_shipping_r[0]?.shipping_id,
      pickupDate: moment(vehicleContext?.state?.vehicle?.pickupDate).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      estimatedArrivalDate: moment(
        vehicleContext?.state?.vehicle?.estimatedArrivalDate
      ).format("YYYY-MM-DD HH:mm:ss"),
    };

    const url = `${urlEnum.reserveForVehicle}/${vehicleContext?.state?.vehicle?.vehicle_shipping_r[0]?.id}`;
    const response = await postData(url, data);
    // update documents
    if (response) vehicleContext.updateVehicle(response?.data?.vehicle);

    props.handleModalReserve();
  }

  return (
    <div style={{ margin: 15 }}>
      <GenericModal
        open={props.open}
        onClose={handleOpenCloseModal}
        title={Vocabulary.reserveTransportMessage5_2}
      >
        <Grid container spacing={3} style={{ marginTop: 10 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid item xs={12} md={6} xl={6}>
              <DesktopDatePicker
                disabled={props.disabled}
                format={europeanDate}
                label={Vocabulary.pickupDate}
                value={moment(vehicleContext?.state?.vehicle?.pickupDate)}
                onChange={(e: any) =>
                  vehicleContext.onChangeVehicleTransportDate(e)
                }
                slotProps={{
                  textField: { size: "small", style: { width: "100%" } },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={6}>
              <DesktopDatePicker
                disabled={props.disabled}
                format={europeanDate}
                label={Vocabulary.estimatedArrivalDate}
                value={moment(
                  vehicleContext?.state?.vehicle?.estimatedArrivalDate
                )}
                onChange={(e: any) =>
                  vehicleContext.onChangeVehicleTransportEstimatedDate(e)
                }
                slotProps={{
                  textField: { size: "small", style: { width: "100%" } },
                }}
              />
            </Grid>
          </LocalizationProvider>

          <Grid item xs={6}>
            <Button
              size="large"
              onClick={props.handleModalReserve}
              variant="contained"
              color="secondary"
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={reserveVehicle}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
      <ShippingModal
        open={openModalClient}
        title={"ADAUGA UN TRANSPORTATOR NOU "}
        editedShipping={null}
        onClose={() => {
          props.refreshShippingList();
          setOpenModalClient(false);
        }}
      />
    </div>
  );
}
