import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  // IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Toolbar,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ThemeProvider } from "@emotion/react";
import { getMuiTheme } from "../../Utils/globalStyleTables";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/urlEnum";
import GenericModal from "../../Modal/GenericModal";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  AutocompleteUser,
  CanceledClientMessageModel,
  ClientMessageModel,
  StatusModel,
  // WhatsappDirectMessage,
  // WhatsappTemplateMessage,
} from "../../Models/Models";
import {
  Nomenclator,
  VehicleBrandModel_Model,
} from "../../Models/VehicleModel";
import moment, { Moment } from "moment";
import { europeanDate, europeanDateWithTime } from "../../Utils/formatDate";
import { postData } from "../../Services/postData";
import { CacheContext } from "../../Context/cacheContext";
import {
  DEFAULT_MAX_NUMBER_OF_MESSAGES,
  PER_PAGE_DEFAULT,
  Settings,
  clientMessagesStatus,
  componentNames,
  tableLayoutOnMobile,
} from "../../Utils/Constants";
import { exportTable, isMobile } from "../../Utils/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";
import DinamicTabs from "../DinamicTabs";
import { clientMessagesType } from "../../Utils/autocompleteEnum";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import HistoryComponent from "../HistoryComponent";
import LegendComponent from "../LegendComponent";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
// import SendIcon from "@mui/icons-material/Send";

type FormStateModel = {
  vin: string | null;
  brand: Nomenclator | null;
  model: VehicleBrandModel_Model | null;
  clientName: string | null;
  order: string | undefined;
  page: number;
  perPage: number;
  count: number;
  startDate: Moment | string;
  endDate: Moment | string;
  status: StatusModel[] | undefined;
  user: AutocompleteUser | undefined | null;
  accesForAllMessages?: any;
};
export interface MainTableProps {
  children?: React.ReactNode;
  index?: number;
  value?: number;
}

export default function ClientMessages(props: any) {
  const { tabsLabelsArray, value, status } = props;
  const [messages, setMessages] = useState<Array<ClientMessageModel>>([]);
  const [selected, setSelected] = useState<ClientMessageModel | null>(null);
  // const [text, setText] = useState("");
  // const [template, setTemplate] = useState("");
  const [openAccordion, setOpenAccordion] = useState(false);
  const [messagesCount, setMessagesCount] = useState(0);
  const [cancelModal, setCancelModal] =
    useState<CanceledClientMessageModel | null>(null);
  const [clientMessageStatus, setClientMessageStatus] = useState<number>();
  const [associatedUser, setAssociatedUser] = useState<
    number | null | undefined
  >();
  const userId = localStorage.getItem("useId");

  const [formState, setFormState] = useState<FormStateModel>({
    vin: "",
    brand: null,
    model: null,
    clientName: "",
    order: "desc",
    page: 0,
    perPage: PER_PAGE_DEFAULT,
    count: 0,
    startDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    status: undefined,
    user: null,
  });
  const cacheContext = useContext(CacheContext);
  function TabPanel(props: MainTableProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={"span"} variant={"body2"}>
              {children}
            </Typography>
          </Box>
        )}
      </div>
    );
  }

  /**
   * get messages on change
   */
  useEffect(() => {
    getMessages();
  }, [
    formState.page,
    formState.perPage,
    props.value,
    formState.brand,
    formState.model,
    formState.order,
    formState.status,
    formState.user,
    formState.startDate,
    formState.endDate,
  ]);

  /**
   * get messages form server and override local messages
   */
  async function getMessages(startDate?: any, endDate?: any) {
    const url = formAPIClientMessagesUrl(startDate, endDate);
    try {
      const response = await getData(url);
      setMessages(response.data.messages);
      setMessagesCount(response.data.numberOfUnreadMessages);
      props.setUnassignedMessagesCount(response.data.numberOfUnseenMessages);

      const newForm = Object.assign({}, formState);
      newForm.count = response.data.numberOfMessages;
      newForm.startDate = startDate ? startDate : formState.startDate;
      newForm.endDate = endDate ? endDate : formState.endDate;
      setFormState(newForm);
    } catch (e) {
      setMessages([]);
    }
  }

  /**
   * get the api url
   * @returns string
   */
  function formAPIClientMessagesUrl(startDate?: any, endDate?: any) {
    const statusCode =
      props.status == "" ? props.tabsLabelsArray[0].code : props.status;

    const statusIds: any[] = [];
    if (formState.status) {
      formState.status.forEach((status) => {
        statusIds.push(status.id);
      });
    }
    return `${urlEnum.clientMessages}?type=${
      clientMessagesType.financingRequest
    }&page=${formState.page}&perPage=${formState.perPage}&vin=${
      formState.vin
    }&brand_id=${formState.brand?.id || ""}&model_id=${
      formState.model?.id || ""
    }&clientName=${formState.clientName}&order=${
      formState.order
    }&startDate=${moment(startDate ? startDate : formState.startDate).format(
      "YYYY-MM-DD"
    )}&endDate=${moment(endDate ? endDate : formState.endDate).format(
      "YYYY-MM-DD"
    )}&firstSeenUser=${userId}&status=${statusCode}&filterStatus=${statusIds}&filterUser=${
      formState.user?.id
    }`;
  }

  /**
   * set the id of the current user as the one that has seen the message
   * @param messageId
   */
  async function sendFirstSeenBy(messageId: number) {
    const url = `${urlEnum.clientMessages}/${messageId}`;
    const response = await postData(url);
    const s = messages.find((message) => message.id === response?.data.id);
    if (s) {
      s.first_seen_user_id = response?.data?.first_seen_user_id;
      setMessages(messages.slice());
      setAssociatedUser(response?.data?.first_seen_user_id);
    }
  }

  /**
   * changes textfields values
   * @param event
   */
  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
      page: 0,
    });
  }

  /**
   * changes textfields values
   * @param event
   */
  function onChangeStartDate(event: any) {
    const newFormState = Object.assign({}, formState);
    newFormState.startDate = event?._d;
    getMessages(event?._d, formState.endDate);
  }

  /**
   * changes textfields values
   * @param event
   */
  function onChangeEndDate(event: any) {
    const newFormState = Object.assign({}, formState);
    newFormState.endDate = event?._d;
    getMessages(formState.startDate, event?._d);
  }

  function onTextFieldKeyUp(e: React.KeyboardEvent) {
    if (e.key === "Enter") {
      getMessages();
    }
  }

  function onFilterBlur() {
    getMessages();
  }

  /**
   *
   * @param rowIndex
   */
  function openMessage(rowIndex: number) {
    const crtMsg: ClientMessageModel = messages[rowIndex];
    if (!crtMsg.first_seen_user_id) {
      sendFirstSeenBy(crtMsg.id);
    } else setAssociatedUser(crtMsg.first_seen_user_id);
    setSelected(crtMsg);
    setClientMessageStatus(crtMsg.status_id);
    setAssociatedUser(crtMsg.first_seen_user_id);
  }

  // /**
  //  *
  //  * @param rowIndex
  //  */
  // function sendTemplate(phone: any) {
  //   sendWhatsappTemplate(phone);
  // }
  // /**
  //  *
  //  * @param rowIndex
  //  */
  // function sendText(phone: any) {
  //   sendWhatsappText(phone);
  // }

  // columns for My Messages
  const columns = [
    {
      name: "name",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: Vocabulary.email,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phone",
      label: Vocabulary.phone,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "status",
      label: Vocabulary.status,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status: StatusModel | undefined) => (
          <Typography>{status?.nume}</Typography>
        ),
      },
    },
    {
      name: "vehicle",
      label: Vocabulary.vehicle,
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <>
            {messages[rowIndex]?.vin ? (
              <Typography>
                {messages[rowIndex].brand} {messages[rowIndex].model}
                <br />
                {Vocabulary.vin}: {messages[rowIndex].vin}
              </Typography>
            ) : null}
          </>
        ),
      },
    },
    {
      name: "created_at",
      label: Vocabulary.date,
      options: {
        filter: true,
        sort: false,
        downloadType: "date",
        customBodyRender: (date: string | null) => {
          const d = moment.utc(date);
          return (
            <Typography>{d.local().format(europeanDateWithTime)}</Typography>
          );
        },
      },
    },
    {
      name: "first_seen_user",
      label: " ",
      options: {
        sort: false,

        customBodyRender: (user: any) => {
          return (
            <div style={{ textAlign: "center", minWidth: 100 }}>
              <Typography style={{ fontSize: 10 }}>
                {Vocabulary.alocatedFor}
              </Typography>
              <Typography style={{ fontWeight: 600, fontSize: 15 }}>
                {user?.name}
              </Typography>
            </div>
          );
        },
      },
    },

    {
      name: "",
      label: Vocabulary.options,
      options: {
        filter: false,
        sort: false,
        download: false,
        viewColumns: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div style={{ float: "right" }}>
            <Tooltip title={Vocabulary.edit}>
              <ModeEditIcon
                style={{
                  marginRight: 15,
                  height: 30,
                  cursor: "pointer",
                  color: "#4449",
                }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  openMessage(rowIndex);
                }}
              />
            </Tooltip>
          </div>
        ),
      },
    },
  ];

  // columns
  const columnsAllocated = [
    {
      name: "name",
      label: Vocabulary.name,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: Vocabulary.email,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "phone",
      label: Vocabulary.phone,
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "vehicle",
      label: Vocabulary.vehicle,
      options: {
        filter: true,
        sort: false,
        customBodyRenderLite: (rowIndex: number) => (
          <>
            {messages[rowIndex]?.vin ? (
              <Typography>
                {messages[rowIndex].brand} {messages[rowIndex].model}
                <br />
                {Vocabulary.vin}: {messages[rowIndex].vin}
              </Typography>
            ) : null}
          </>
        ),
      },
    },
    {
      name: "status",
      label: Vocabulary.status,
      options: {
        filter: true,
        sort: false,
        customBodyRender: (status: any) => (
          <Typography>{status?.nume}</Typography>
        ),
      },
    },
    {
      name: "updated_at",
      label: Vocabulary.date,
      options: {
        filter: true,
        sort: false,
        downloadType: "date",
        customBodyRender: (date: string | null) => {
          const d = moment.utc(date);
          return (
            <Typography>{d.local().format(europeanDateWithTime)}</Typography>
          );
        },
      },
    },
    {
      name: "",
      label: Vocabulary.options,
      options: {
        filter: false,
        sort: false,
        download: false,
        customBodyRenderLite: (rowIndex: number) => (
          <div style={{ float: "right" }}>
            <Tooltip title={Vocabulary.edit}>
              <ModeEditIcon
                style={{
                  marginRight: 15,
                  height: 30,
                  cursor: "pointer",
                  color: "#4449",
                }}
                onClick={(event: any) => {
                  event.stopPropagation();
                  openMessage(rowIndex);
                }}
              />
            </Tooltip>
          </div>
        ),
      },
    },
  ];

  const options: any = {
    rowsPerPage: formState.perPage,
    page: formState.page,
    selectableRows: "none" as any,
    responsive: tableLayoutOnMobile,
    filterType: "dropdown",
    filter: false,
    download: true,
    sort: false,
    serverSide: true,
    count: formState?.count,
    viewColumns: false,
    fixedHeader: false,
    fixedSelectColumn: false,
    print: false,
    search: false,
    rowHover: false,
    textLabels: {
      body: {
        noMatch: Vocabulary.noData,
      },
      viewColumns: {
        title: Vocabulary.viewColumns,
      },
      toolbar: {
        downloadCsv: Vocabulary.downloadExcel,
        viewColumns: Vocabulary.selectColumns,
      },
    },
    rowsPerPageOptions: [
      15,
      50,
      100,
      formState?.count > 100 ? formState?.count : 1000,
    ],
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
      },
      filename: (
        Vocabulary.financingRequests +
        "_" +
        props.tabsLabelsArray[props.value]?.label +
        ".xlsx"
      ).toLowerCase(),
    },
    setTableProps: () => {
      return {
        size: "small",
      };
    },
    setRowProps: (row: [], dataIndex: number, rowIndex: number) => {
      let styleProps = {};
      if (messages && !messages[rowIndex]?.first_seen_user_id) {
        styleProps = {
          filter: "blur(5px)",
        };
      }
      if (messages[rowIndex]?.status?.culoare) {
        styleProps = {
          ...styleProps,
          backgroundColor: messages[rowIndex]?.status?.culoare,
        };
      }

      return {
        style: { ...styleProps, cursor: "pointer" },
      };
    },
    onRowClick: (
      rowData: string[],
      rowMeta: { dataIndex: number; rowIndex: number }
    ) => {
      openMessage(rowMeta.rowIndex);
    },
    onChangePage: (page: number) => {
      setFormState({
        ...formState,
        page: page,
      });
      window.scrollTo(0, 0);
    },
    onChangeRowsPerPage: (numberOfRows: number) => {
      setFormState({
        ...formState,
        perPage: numberOfRows,
      });
      window.scrollTo(0, 0);
    },
    onDownload: (buildHead: any, buildBody: any, columns: any, data: any) => {
      let indefOfVehicleColumn = 0;
      let indexOfDateColumn = 0;
      columns = columns.map((column: any, index: number) => {
        if (column.name == "vehicle") indefOfVehicleColumn = index;
        else if (column.name == "first_seen_user")
          column.label = Vocabulary.user;
        else if (column.name == "created_at") {
          indexOfDateColumn = index;
        }
        return column;
      });
      const alteredData = data.map((column: any, index: number) => {
        column.data = column?.data?.map((value: any, key: number) => {
          if (typeof value == "object") {
            if (value) {
              const name = Object.values(value)[1];
              value = name;
            }
          }
          if (indefOfVehicleColumn == key) value = messages[index].vin;
          else if (indexOfDateColumn == key)
            value = moment.utc(value).local().format(europeanDateWithTime);
          return value;
        });

        return column;
      });
      return `${buildHead(columns)}${buildBody(alteredData)}`;
    },
  };

  /**
   *
   * @param event
   * @param value
   */
  function handleChangeStatus(
    event: React.MouseEvent<HTMLElement>,
    value: number
  ) {
    setClientMessageStatus(value);
  }

  /**
   * saves the status for financing request
   */
  async function handleChangeClientMessageStatus(id: number | undefined) {
    const url = `${urlEnum.clientMessages}/updateStatus/${id}/${clientMessageStatus}/${associatedUser}`;
    try {
      await postData(url);
      getMessages();
    } catch (e) {
      setMessages([]);
    }
  }

  /**
   * saves the status for client message
   */
  async function handleCancelClientMessage(id: number | null | undefined) {
    const url = `${urlEnum.clientMessages}/cancelMessage/${id}`;
    try {
      await postData(url);
      getMessages();
    } catch (e) {
      setMessages([]);
    }
  }

  /**
   *
   * @param array
   * @param id
   * @returns
   */
  function getValueAutocomplete(
    array: Array<any>,
    id: number | null | undefined
  ) {
    if (array.length !== 0) {
      const index = array.findIndex((item: any) => {
        if (id && +item.id === +id) return item;
        return null;
      });
      if (index < 0) return null;
      return {
        id: array[index].id,
        nume: array[index].nume,
      };
    }
    return null;
  }

  /**
   * jsx for the modal
   * @returns
   */
  function renderModal() {
    return (
      <GenericModal
        open={!!selected}
        notes={{ entityId: selected?.id, entity: "financingRequest" }}
        onClose={() => {
          setSelected(null);
          getMessages();
        }}
        title={Vocabulary.financingRequest}
        buttons={
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSelected(null);
                handleChangeClientMessageStatus(selected?.id);
              }}
            >
              {Vocabulary.save}
            </Button>
          </div>
        }
      >
        <h2>
          {selected?.brand} {selected?.model}
        </h2>
        {Vocabulary.vin}: {selected?.vin}
        <br />
        <br />
        <Divider />
        <br />
        <Typography>
          {Vocabulary.name}: <strong>{selected?.name}</strong>
        </Typography>
        <Typography>
          {Vocabulary.email}: <strong>{selected?.email}</strong>
        </Typography>
        <Typography>
          {Vocabulary.phone}: <strong>{selected?.phone}</strong>
        </Typography>
        {selected?.details && (
          <Typography>
            {Vocabulary.financingPeriod}:{" "}
            <strong>{selected?.details + Vocabulary.months}</strong>
          </Typography>
        )}
        <Typography>
          {Vocabulary.source}: <strong>{selected?.source}</strong>
        </Typography>
        <Typography
          style={{
            display: "flex",
            justifyContent: "center ",
            padding: "10px 0",
          }}
        >
          <strong>{Vocabulary.status}</strong>
        </Typography>
        <ToggleButtonGroup
          color="primary"
          value={clientMessageStatus}
          exclusive
          orientation={isMobile() ? "vertical" : "horizontal"}
          aria-label="text alignment"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {cacheContext.cache.clientMessagesStatus.map(
            (value: ClientMessageModel, key: number) => {
              return (
                <ToggleButton
                  value={value.id}
                  onClick={(
                    event: React.MouseEvent<HTMLElement>,
                    newValue: number
                  ) => handleChangeStatus(event, newValue)}
                >
                  {value.nume}
                </ToggleButton>
              );
            }
          )}
        </ToggleButtonGroup>
        {props.isAdmin ||
          (props.accesForAllMessages && (
            <Grid container style={{ marginTop: "40px" }}>
              <Grid item xs={12} md={12} lg={12}>
                <Autocomplete
                  id="users"
                  freeSolo={false}
                  disablePortal
                  getOptionLabel={(option: any) => option.nume}
                  size="medium"
                  options={props.cacheContext.cache.users}
                  value={getValueAutocomplete(
                    props.cacheContext.cache?.users,
                    associatedUser
                  )}
                  onChange={(event: any, newValue: any) =>
                    setAssociatedUser(newValue?.id)
                  }
                  sx={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={Vocabulary.assignToAUser}
                      variant="outlined"
                      size="medium"
                    />
                  )}
                />
              </Grid>
            </Grid>
          ))}
        {selected?.logs.length ? (
          <HistoryComponent
            logs={selected?.logs}
            firstAllocMessage={Vocabulary.clientMessageWasAllocated}
            firstTimeStatusSetMessage={Vocabulary.clientMessageSetStatus}
            statusChangedMessage={Vocabulary.clientMessageStatusChanged}
            type={Vocabulary.financingRequest}
            createdMessage={Vocabulary.financingRequestCreated}
          />
        ) : null}
      </GenericModal>
    );
  }

  /**
   * jsx for the confirm cancel modal
   * @returns
   */
  function renderCancelModal() {
    return (
      <GenericModal
        open={!!cancelModal?.opened}
        onClose={() => {
          setCancelModal({ messageId: null, opened: false });
        }}
        title={Vocabulary.confirm}
        buttons={
          <div
            style={{
              display: "flex",
              padding: 10,
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setCancelModal({ messageId: null, opened: false })}
            >
              {Vocabulary.close}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setCancelModal({ messageId: null, opened: false });
                handleCancelClientMessage(cancelModal?.messageId);
              }}
            >
              {Vocabulary.confirm}
            </Button>
          </div>
        }
      >
        <Typography>{Vocabulary.cancelMessage}</Typography>
      </GenericModal>
    );
  }

  // function handleChangeText(e: any) {
  //   setText(e.target.value);
  // }

  // /**
  //  * jsx for the whatsapp direct messages
  //  * @returns
  //  */
  // function directMessageWhatsapp() {
  //   return (
  //     <div>
  //       <br />
  //       <br />
  //       <Divider />
  //       <br />
  //       <br />
  //       <div style={{ display: "flex", margin: 10 }}>
  //         <WhatsAppIcon style={{ color: "#128C7E" }}></WhatsAppIcon>
  //         <Typography>
  //           <strong>{Vocabulary.whatsappMesajDirect}</strong>
  //         </Typography>
  //       </div>
  //       <div style={{ display: "flex" }}>
  //         <TextField
  //           onChange={(e: any) => {
  //             handleChangeText(e);
  //           }}
  //           id="vin"
  //           fullWidth
  //           label={Vocabulary.clientDirectMessage}
  //           variant="outlined"
  //           size="small"
  //           multiline
  //           rows={3}
  //         ></TextField>
  //         <div>
  //           <IconButton
  //             disabled={text.length === 0}
  //             onClick={() => {
  //               sendText(selected?.phone);
  //             }}
  //           >
  //             <SendIcon
  //               style={{
  //                 cursor: "pointer",
  //                 color: text.length === 0 ? "#4444" : "#128C7E",
  //               }}
  //             />
  //           </IconButton>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // /**
  //  * jsx for the whatsapp direct messages
  //  * @returns
  //  */
  // function templatesWhatsapp() {
  //   return (
  //     <div>
  //       <br />
  //       <br />
  //       <Divider />
  //       <br />
  //       <br />
  //       <div style={{ display: "flex", margin: 10 }}>
  //         <WhatsAppIcon style={{ color: "#128C7E" }}></WhatsAppIcon>
  //         <Typography>
  //           <strong>{Vocabulary.templateWhatsapp}</strong>
  //         </Typography>
  //       </div>
  //       <div style={{ display: "flex" }}>
  //         <Autocomplete
  //           size="small"
  //           fullWidth
  //           disablePortal
  //           disabled={props.editButton}
  //           onChange={(e: any) => setTemplate(e.target.textContent)}
  //           options={[
  //             "Mesaj initial",
  //             "Mesaj de reamintire",
  //             "Mesaj de propunere oferta",
  //           ]} //TODO
  //           renderInput={(params) => (
  //             <TextField
  //               label="Template"
  //               name="tip_vehicul_id"
  //               type="search"
  //               {...params}
  //             />
  //           )}
  //         />
  //         <div>
  //           <IconButton
  //             disabled={template.length === 0}
  //             onClick={() => {
  //               sendTemplate(selected?.phone);
  //             }}
  //           >
  //             <SendIcon
  //               style={{
  //                 cursor: "pointer",
  //                 color: template.length === 0 ? "#4444" : "#128C7E",
  //               }}
  //             />
  //           </IconButton>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  function renderFilterForm() {
    return (
      <>
        <Grid
          container
          spacing={3}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label={Vocabulary.startDate}
                value={moment(formState?.startDate)}
                format={europeanDate}
                maxDate={moment(formState?.endDate)}
                onChange={(e: any) => onChangeStartDate(e)}
                slotProps={{ textField: { size: "small", fullWidth: true } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label={Vocabulary.endDate}
                value={moment(formState?.endDate)}
                minDate={moment(formState?.startDate)}
                onChange={(e: any) => onChangeEndDate(e)}
                format={europeanDate}
                slotProps={{ textField: { size: "small", fullWidth: true } }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              onChange={onChange}
              value={formState.vin}
              id="vin"
              name="vin"
              label={Vocabulary.vin}
              variant="outlined"
              size="small"
              inputProps={{
                style: { textTransform: "uppercase" },
              }}
              onKeyUp={onTextFieldKeyUp}
              onBlur={onFilterBlur}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              size="small"
              getOptionLabel={(option: any) => option?.nume}
              id="brand_id"
              value={formState.brand}
              onChange={(event, value: any) => {
                setFormState({
                  ...formState,
                  brand: value,
                  model: null,
                  page: 0,
                });
              }}
              onBlur={onFilterBlur}
              options={cacheContext.cache?.brands}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="brand_id"
                  label={Vocabulary.brand}
                  name="brand_id"
                />
              )}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              size="small"
              getOptionLabel={(option: any) => option?.nume}
              id="model_r"
              value={formState.model}
              onChange={(event, value: any) => {
                setFormState({
                  ...formState,
                  model: value,
                  page: 0,
                });
              }}
              onBlur={onFilterBlur}
              options={
                formState.brand
                  ? cacheContext.cache?.models.filter(
                      (model: VehicleBrandModel_Model) =>
                        model.brand_id === formState.brand?.id
                    )
                  : []
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  id="model_id"
                  label={Vocabulary.model}
                  name="model_id"
                />
              )}
            />
          </Grid>
          {(props.status == clientMessagesStatus.Toate ||
            props.status == clientMessagesStatus.Alocate) && ( //have status filtering only at assigned and all messages
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Autocomplete
                size="small"
                getOptionLabel={(option: any) => option?.nume}
                id="status_id"
                multiple
                value={formState.status ? formState.status : []}
                onChange={(event, value: any) => {
                  setFormState({
                    ...formState,
                    status: value,
                    page: 0,
                  });
                }}
                onBlur={onFilterBlur}
                options={cacheContext.cache.clientMessagesStatus}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    id="status_id"
                    label={Vocabulary.status}
                    name="status_id"
                  />
                )}
              />
            </Grid>
          )}
          {props.status != clientMessagesStatus.Alocate && (
            <Grid item xs={12} md={12} lg={12}>
              <Autocomplete
                id="filterUsers"
                freeSolo={false}
                disablePortal
                getOptionLabel={(option: any) => option.nume}
                size="small"
                options={props.cacheContext.cache?.users}
                value={formState.user ? formState.user : null}
                onChange={(event: any, newValue: any) =>
                  setFormState({
                    ...formState,
                    user: newValue,
                    page: 0,
                  })
                }
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={Vocabulary.user}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              onChange={onChange}
              value={formState.clientName}
              id="clientName"
              name="clientName"
              label={Vocabulary.client}
              variant="outlined"
              size="small"
              onKeyUp={onTextFieldKeyUp}
              onBlur={onFilterBlur}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <FormControl size="small" fullWidth>
              <InputLabel>{Vocabulary.order}</InputLabel>
              <Select
                fullWidth
                id="order"
                value={formState.order}
                label={Vocabulary.order}
                name="order"
                onBlur={onFilterBlur}
                onChange={(event: SelectChangeEvent<string>) => {
                  setFormState({
                    ...formState,
                    [event.target.name]: event.target.value,
                    page: 0,
                  });
                }}
              >
                <MenuItem value={"asc"}>{Vocabulary.asc}</MenuItem>
                <MenuItem value={"desc"}>{Vocabulary.desc}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <LegendComponent
          title={Vocabulary.status}
          data={props.cacheContext.cache.clientMessagesStatus}
        />
      </>
    );
  }

  /**
   *
   * @returns full warning message for desktop
   */
  const getWarningMessage = () => {
    if (getShortWarningMessage())
      return getShortWarningMessage() + getLongWarningMessageVersion();
    else return null;
  };

  /**
   *
   * @returns the long version of warning message only showed oon descktop if automated allocation is on
   */
  const getLongWarningMessageVersion = () => {
    let message = "";
    const isAutomatedAllocationON = props.cacheContext.cache.settings.find(
      (user: any) => user.slug == Settings.automatedClientMessages
    )?.value;
    if (isAutomatedAllocationON == 1) {
      const maxNrOfMsgs = props.cacheContext.cache.settings.find(
        (user: any) => user.slug == Settings.maxNumberForMessages
      )?.value;
      message = ` , din ${
        maxNrOfMsgs ? maxNrOfMsgs : DEFAULT_MAX_NUMBER_OF_MESSAGES
      } maxime!`;
    }
    return message;
  };

  /**
   *
   * @returns the warning message to be show to let user know how many unread car orders he has
   * this is the short version to be shown only on mobile and if the automated allocation is off
   */
  const getShortWarningMessage = () => {
    let message = "";
    if (messagesCount) {
      message = `Ai ${messagesCount} `;
      if (messagesCount == 1) {
        message += "mesaj necitit";
      } else {
        message += "mesaje necitite";
      }
    }
    return message;
  };

  /**
   * export table as excel
   */
  const exportData = (props: any) => {
    const statusCode = status === "" ? tabsLabelsArray[0].code : status;

    const statusIds: any[] = [];
    if (formState.status) {
      formState.status.forEach((status) => {
        statusIds.push(status.id);
      });
    }
    let exportedColumns = props?.columns;
    exportedColumns = exportedColumns.filter((column: any) => {
      if (column.download == true) return column.name;
    });

    const fileName = (
      Vocabulary.clientMessages +
      "_" +
      tabsLabelsArray[value]?.label
    ).toLowerCase();

    const url = `${urlEnum.clientMessages}/export/table/data`;
    const type = clientMessagesType.financingRequest;
    exportTable(
      url,
      { formState, type, statusCode, statusIds, userId },
      fileName,
      exportedColumns
    );
  };

  /**
   *
   * @param props
   * @returns
   */
  const CustomToolbar = (props: any) => {
    return (
      <Toolbar>
        <Button
          startIcon={<CloudDownloadIcon />}
          onClick={(event) => exportData(props)}
        >
          {Vocabulary.exportTable}
        </Button>
      </Toolbar>
    );
  };

  return (
    <Grid container spacing={3} style={{ padding: isMobile() ? 0 : 20 }}>
      <Grid item lg={2} md={2} sm={12} xs={12}>
        {isMobile() ? (
          <Accordion
            expanded={openAccordion}
            onChange={() => setOpenAccordion(!openAccordion)}
            style={{ zIndex: 10 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <TuneIcon style={{ marginRight: 10 }}></TuneIcon>
              <Typography style={{ marginTop: 1 }}>
                {Vocabulary.options}{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>{renderFilterForm()}</AccordionDetails>
          </Accordion>
        ) : (
          renderFilterForm()
        )}
      </Grid>
      <Grid item lg={10} md={10} sm={12} xs={12}>
        <ThemeProvider theme={getMuiTheme()}>
          <DinamicTabs
            name={componentNames.DinamicTabs}
            labels={props.tabsLabelsArray}
            warningMessage={
              getWarningMessage() !== "" ? getWarningMessage() : null
            }
            shortWarningMessage={getShortWarningMessage()}
            value={props.value}
            handleChangeTab={(
              event: React.FormEvent<HTMLInputElement>,
              index: number
            ) => {
              props.handleChange(event, index);
              setFormState({
                ...formState,
                status: undefined,
                user: null,
                page: 0,
              });
            }}
            hideAddNew={true}
          />
          <div
            style={{
              marginLeft: isMobile() ? -15 : -25,
              marginRight: isMobile() ? -15 : -25,
              marginTop: isMobile() ? -25 : -25,
              width: "auto",
            }}
          >
            <TabPanel value={props.value} index={0}>
              <MUIDataTable
                title=""
                data={messages}
                columns={columns}
                options={options}
                components={{
                  TableToolbar: CustomToolbar,
                }}
              />
            </TabPanel>
            <TabPanel value={props.value} index={1}>
              <MUIDataTable
                title=""
                data={messages}
                columns={columnsAllocated}
                options={options}
                components={{
                  TableToolbar: CustomToolbar,
                }}
              />
            </TabPanel>
            <TabPanel value={props.value} index={2}>
              <MUIDataTable
                title=""
                data={messages}
                columns={columns}
                options={options}
                components={{
                  TableToolbar: CustomToolbar,
                }}
              />
            </TabPanel>
            <TabPanel value={props.value} index={3}>
              <MUIDataTable
                title=""
                data={messages}
                columns={columns}
                options={options}
                components={{
                  TableToolbar: CustomToolbar,
                }}
              />
            </TabPanel>
          </div>
        </ThemeProvider>

        {selected && renderModal()}
        {cancelModal && renderCancelModal()}
      </Grid>
    </Grid>
  );
}