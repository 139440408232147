/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { Button, Grid, InputLabel, Menu, MenuItem } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { CacheContext } from "../Context/cacheContext";
import { updateData } from "../Services/updateData";
import { urlEnum } from "../Utils/urlEnum";
import { Vocabulary } from "../Utils/Vocabulary";
import GenericModal from "../Modal/GenericModal";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { europeanDate } from "../Utils/formatDate";
import { VehiclesStatus } from "../Utils/autocompleteEnum";
import { VehicleContext } from "../Context/vehicleContext";
import { VehicleStatusModel } from "../Models/VehicleModel";

export default function VehicleStatus() {
  const vehicleContext: any = useContext(VehicleContext);
  const cacheContext = useContext(CacheContext) as any;
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const open = Boolean(anchorEl);
  const actualDate: any = new Date();
  const [date, setDate] = useState(moment(actualDate));
  const [statusArray, setStatusArray] = useState<any>([]);
  const [status, setStatus]: any = useState({
    id: "",
    nume: "",
    culoare: "",
  });


  //set statuses
  useEffect(() => {
    if (cacheContext.cache.length !== 0) {
      const array = cacheContext.cache.vehicleStatus.filter(
        (status: any) =>
          // status.nume !== VehiclesStatus.received &&
          status.nume !== VehiclesStatus.reserved &&
          status.nume !== VehiclesStatus.advancePaid &&
          status.nume !== VehiclesStatus.contractToSign &&
          status.nume !== VehiclesStatus.sold &&
          status.nume !== VehiclesStatus.paid &&
          status.nume !== VehiclesStatus.unpaid
      );
      setStatusArray(array);
    }
  }, [cacheContext.cache]);

  //set status for vehicle
  useEffect(() => {
    if (
      vehicleContext?.state?.vehicle &&
      Object.keys(vehicleContext?.state?.vehicle).length !== 0
    ) {
      const status = cacheContext.cache.vehicleStatus.find(
        (status: VehicleStatusModel) =>
          status.id === vehicleContext?.state?.vehicle?.status_id
      );
      if (status) {
        setStatus(status);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleContext.state?.vehicle]);

  /**
   *
   * @param event
   */
  function handleClick(
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) {
    if (
      status.nume === VehiclesStatus.reserved ||
      status.nume === VehiclesStatus.advancePaid ||
      status.nume === VehiclesStatus.contractToSign ||
      status.nume === VehiclesStatus.sold
    )
      return null;
    if (!anchorEl) setAnchorEl(event.currentTarget);
  }

  /**
   *
   *
   */
  function updateStatus() {
    const urlVehicles = `${urlEnum.vehicles}/statusVehicles/${vehicleContext?.state?.vehicle?.id}`;
    let data: any = "";
    if (status.nume === VehiclesStatus.readyForSale) {
      data = {
        status_id: status.id,
        data_vanzare: null,
        vanzator_id: null,
        data_pregatire_vanzare: moment(date).format("YYYY-MM-DD"),
      };
    } else {
      data = {
        status_id: status.id,
        data_vanzare: null,
        vanzator_id: null,
        data_pregatire_vanzare: null,
      };
    }

    updateData(urlVehicles, data)
      .then((res) => {
        const newVehicleData = {
          ...vehicleContext?.state?.vehicle,
          status_id: res.data.data.status.id,
          data_vanzare: res.data.data.data_vanzare,
          vanzator: res.data.data.vanzator,
        };
        vehicleContext.updateVehicle(newVehicleData);
        setOpenStatusModal(false);
      })
      .catch((error) => {
        setOpenStatusModal(false);
      });
  }

  /**
   *
   * @param st
   */
  function handleChange(st?: any) {
    if (st) {
      setOpenStatusModal(true);
      setStatus(st);
    }
    setAnchorEl(null);
  }

  function handleDateChange(date?: any) {
    setDate(moment(date));
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: status?.culoare,
          width: "auto",
          padding: 10,
          borderRadius: 5,
        }}
        onClick={(e) => handleClick(e)}
      >
        <InputLabel
          style={{
            marginRight: 20,
            fontSize: 15,
            color: "#fff",
            textShadow: "1px 1px 2px #000",
            fontWeight: 800,
          }}
        >
          {Vocabulary.status.toUpperCase()}
        </InputLabel>
        <Button
          variant="contained"
          size="large"
          style={{
            backgroundColor: status?.culoare,
            color: "#fff",
            boxShadow: "none",
            textShadow: "1px 2px 2px #000",
            fontWeight: 800,
          }}
        >
          {status.nume}
        </Button>

        <Menu
          open={open}
          anchorEl={anchorEl}
          onClose={() => handleChange()}
          
        >
          {statusArray?.map((stEnum: any, index: number) => (
            <MenuItem
              key={`${stEnum.id}-${stEnum.nume}`}
              onClick={() => handleChange(stEnum)}
            >
              {stEnum?.nume.toUpperCase()}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <GenericModal
        open={openStatusModal}
        onClose={() => {
          setOpenStatusModal(false);
          setStatus({
            id: vehicleContext?.state?.vehicle?.status_vehicul_r?.id,
            nume: vehicleContext?.state?.vehicle?.status_vehicul_r?.nume,
            culoare: vehicleContext?.state?.vehicle?.status_vehicul_r?.culoare,
          });
        }}
        title={Vocabulary.saveStatusMessage}
      >
        <p style={{ textAlign: "center", fontSize: 20 }}>
          {Vocabulary.statusMessage}
        </p>
        <Grid container spacing={4} style={{ padding: 10 }}>
          {status.nume === VehiclesStatus.readyForSale ? (
            <Grid item md={12} xs={12} xl={12}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label={Vocabulary.dataPregatireVanzare}
                  slotProps={{ textField: { style: { width: "100%" } } }}
                  defaultValue={moment(actualDate)}
                  onChange={handleDateChange}
                  format={europeanDate}
                />
              </LocalizationProvider>
            </Grid>
          ) : null}
          <Grid item md={6} xs={12}>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => {
                setOpenStatusModal(false);
                setStatus({
                  id: vehicleContext?.state?.vehicle?.status_vehicul_r?.id,
                  nume: vehicleContext?.state?.vehicle?.status_vehicul_r?.nume,
                  culoare:
                    vehicleContext?.state?.vehicle?.status_vehicul_r?.culoare,
                });
              }}
              fullWidth
            >
              {Vocabulary.no}
            </Button>
          </Grid>
          <Grid item md={6} xs={12}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={updateStatus}
              style={{ color: "white" }}
            >
              {Vocabulary.yes}
            </Button>
          </Grid>
        </Grid>
      </GenericModal>
    </>
  );
}
