/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const Vocabulary = {
  login: "Login",
  logout: "Deconectare",
  paid: "Plătit",
  unpaid: "Neplătit",
  uploadInvoice: "Factură",
  uploadOP: "Încarcă OP",
  providerPayment: "Financiar - OP plata furnizor",
  vehicleSale: "Vanzari - factura client",
  buyVehicle: "Achizitie - factura furnizor",
  reserveVehicle: "Vanzari - Rezerva vehicul",
  client: "Client",
  reservedDate: "Data rezervare",
  reserved: "Rezervat",
  reserve: "Rezervare",
  reserve_advance: "Rezervare/Avans",
  generateContract: "Contract",
  generatePaymentDocument: "Doc. plată",
  noInvoice: "Nu există nicio documente",
  date: "Data",
  required: "Acest camp este obligatoriu",
  cancel: "Anulează",
  save: "Salvează",
  currency: "€",
  currencyLei: "Lei",
  sum: "Pret NET Achizitie ",
  yes: "Da",
  no: "Nu",
  unreserveVehicleMessage: "Sunteti sigur ca vreti sa anulati rezervarea?",
  cancelSellVehicleMessage: "Sunteti sigur ca vreti sa anulati vanzarea?",
  unreserveShippingVehicleMessage:
    "Sunteti sigur ca vreti sa anulati transportul?",
  reserveVehicleMessage: "Sunteți sigur că doriți să rezervați acest vehicul?",
  reserveShippingVehicleMessage:
    "Sunteți sigur că doriți să rezervați transport?",
  reserveTransportMessage5_2:
    "Sunteți sigur că doriți să finalizati transportul masinii?",

  successfullyDeleted: "Șters cu succes",
  successfullyAdded: "Salvat cu succes",
  successfullyUpdated: "Actualizat cu succes",
  deleteError: "Eroare la ștergere",
  addError: "Eroare la salvare",
  updateError: "Eroare la actualizare",
  sendEmailError: "Eroare la trimiterea email-ului",
  sendEmailSuccess: "Email trimis cu succes!",
  requiredFields: "Toate câmpurile sunt obligatorii",
  visibleOnSite: "Vizibil pe site",
  notVisibleOnSite: "Nu este vizibil pe site",
  clientsName: "Clienți",
  usersName: "Utilizatori",
  tasksName: "Taskuri",
  settings: "Setări",
  vehicles: "Vehicule",
  reportsName: "Rapoarte",
  administrativeCosts: "Costuri administrative",
  costsName: "Costuri",
  inactivateUser: "Dezactivează utilizator",
  activateUser: "Activează utilizator",
  visibility: "Vizualizează",
  edit: "Editează",
  delete: "Șterge",
  deleteCar: "Șterge mașina",
  options: "Opțiuni",
  noResultsFound: "Nu s-au găsit rezultate",
  addNewClient: "Adaugă client nou",
  editClient: "Editează client",
  add: "Adaugă",
  deleteMessage:
    "Sunteți sigur că doriți să ștergeți acest client/ acesți clienți?",
  deleteClient: "Șterge client",
  received: "Întarzieri recepție",
  preparationDelays: "Întarzieri pregătire",
  userName: "Nume utilizator",
  addNewUser: "Adaugă utilizator nou",
  editUser: "Editează utilizator",
  deleteUser: "Șterge utilizator",
  administrativeLocation: "Locație administrativă",
  userComision: "Comision utilizator",
  location: "Locație",
  password: "Parolă",
  confirmPassword: "Confirmare Parola",
  confirmDelete: "Confirmare stergere",
  confirmDuplication: "Confirmare duplicare",
  changePassword: "Schimbă parola",
  roles: "Roluri",
  selectRoles: "Selectează roluri",
  selectLocation: "Selectează locație",
  selectUser: "Selectează utilizator",
  selectBrand: "Selectează marcă",
  selectModel: "Selectează model",
  selectStatus: "Selectează status",
  requiredField: "Câmp obligatoriu",
  incorrectEmail: "Email incorect",
  seller: "Vânzător",
  saleDate: "Data vânzare",
  totalCosts: "Costuri totale",
  inactiveUsers: "Utilizatori inactivi",
  activeUsers: "Utilizatori activi",
  inactivateUserMessage:
    "Sunți sigur că doriți să dezactivați acest utilizator?",
  activateUserMessage: "Sunți sigur că doriți să activați acest utilizator?",
  companyName: "Nume companie",
  lastName: "Nume",
  firstName: "Prenume",
  phone: "Telefon",
  email: "Email",
  address: "Adresă",
  CUI: "CUI/CIF",
  street: "Stradă",
  city: "Oraș",
  country: "Țară",
  isLegalPerson: "Persoană juridică",
  naturalPerson: "Persoană fizică",
  personType: "Tip persoană",
  invalidCUI: "CUI/CIF invalid",
  firstNumberCNP: "CNP trebuie sa inceapa cu 1, 2, 5, 6, 7 sau 8.",
  numberCNP: "CNP trebuie sa contina 13 cifre.",
  invalidCNP: "CNP invalid",
  logisticsName: "Logistica",
  promo: "Promovare",
  administrative: "Administrative",
  addCosts: "Adaugare cost nou",
  description: "Descriere",
  value: "Valoare",
  status: "Status",
  statuses: "Statusuri",
  statusVehicles: "Status vehicule",
  appSettings: "Setari platforma",
  permissionsName: "Permisiuni",
  color: "Culoare",
  statusType: "Tip status",
  name: "Nume",
  addNewVehicle: "Adaugă vehicul nou",
  addNewStatus: "Adaugă status nou",
  addNewNegotiation: "Adaugă negociere nouă",
  editStatusName: "Editează status",
  editRoles: "Editează rol",
  editPermissions: "Editează permisiune",
  deleteStatus: "Șterge status",
  deleteNegotiation: "Șterge negociere",
  deleteStatusMessage:
    "Sunteți sigur că doriți să ștergeți acest status/ aceste statusuri?",
  deleteNegotiationMessage:
    "Sunteți sigur că doriți să ștergeți acesta negociere/ aceste negocieri?",
  saveStatusMessage: "Sunteți sigur că doriți să salvați statusul?",
  chooseColor: "Alegeți culoarea pentru status",
  statusMessage: "Modificarea statusului implică modificarea datelor pe site.",
  deleteMessageCosts:
    "Sunteți sigur că doriți să ștergeți acest cost / aceste costuri?",
  deleteRolesMessage:
    "Sunteți sigur că doriți să ștergeți acest rol / aceste roluri?",
  deleteCost: "Șterge cost",
  addNewCost: "Adaugă cost nou",
  addNewRole: "Adaugă rol nou",
  addNewPermission: "Adaugă permisiune noua",
  editCosts: "Editează Cost",
  tip_cost_adm_id: "Tip cost",
  marketing: "Marketing",
  logsName: "Loguri",
  log: "Log",
  actions: "Acțiuni",
  model: "Model",
  created: "Creat",
  updated: "Actualizat",
  deleted: "Șters",
  newValue: "Valoare nouă",
  oldValue: "Valoare veche",
  pagination: {
    next: "Urmator",
    previous: "Anterior",
    rowsPerPage: "Rand:",
    displayRows: "din",
    jumpToPage: "Sari la:",
  },
  solds: "Vândute",
  unsolds: "STOC",
  unpaidInvoices: "Neachitate",
  paidInvoices: "Achitate",

  contractToSign: "Contract de semnat",
  paidInAdvance: "Avans Achitat",
  tvaRecover: "TVA de Recuperat",
  reserveds: "Rezervate",
  partialVehicles: "In tranzit",
  salePeriodInformation: "Informații perioadă de vânzare",
  saleDuration: "Vânzare",
  salePreparationDuration: "Pregătire vanzare",
  receivedDuration: "Recepție",
  total: "Total",
  negotiation: "Negocieri",
  nrTelefonPdf: "Nr. Telefon Anunturi PDF",
  procentDobanda: "Procent Dobanda",
  nrMaximNotificari: "Nr. Maxim Notificari Drawer",
  deadlineRaportari: "Deadline Raportari",
  idStatusVandut: "ID Status Vandut",
  comisionVanzare: "Comision Vanzare",
  comisionAchizitie: "Comision Achizitie",
  comisionLogistica: "Comision Logistica",
  commissionsTotals: "Comisioane Totale",
  targetVolum: "Target Volum",
  targetCA: "Target CA",
  targetProfit: "Target Profit",
  exclude: "Exclude",
  dashboard: "Dashboard",
  detaliiVehicul: "Detalii Vehicul",
  financialDetails: "Detalii Financiare",
  intrareGarantie: "Intrare garantie",
  service: "Service",
  detailing: "Detailing",
  vopsitorie: "Vopsitorie",
  costAditional: "Cost Aditional",
  poze: "Poze",
  documente: "Documente",
  statistically: "Statistici",
  brands: "Marci",
  brand: "Marca",
  models: "Modele",
  administrativeLocations: "Locații administrative",
  administrativeLocationsMobile: "Locații adm.",
  totalVehicles: "Total vehicule",
  purchaseValue: "Valoare achiziție",
  saleValue: "Valoare vânzare",
  vehiclesCosts: "Costuri vehicule",
  grossProfit: "Profit brut",
  grossProfitBase: "Profit brut baza",
  netIncome: "Profit net",
  netIncomeBase: "Profit net baza",
  additionalCosts: "Costuri adiționale",
  logisticsCosts: "Costuri logistice",
  marketingCosts: "Costuri marketing",
  guaranteeCosts: "Costuri garanție",
  detailingCosts: "Costuri interventii detailing",
  paintCosts: "Costuri interventii vopsitorie",
  serviceCosts: "Costuri interventii service",
  interestRate: "Dobândă",
  vehicleCostsAverage: "Cost mediu pe vehicul",
  saleDurationAverage: "Durata vânzare medie",
  statisticallySaleDuration: "Durata vânzare",
  netIncomeAverage: "Profit net mediu",
  grossProfitAverage: "Profit brut mediu",
  purchasePriceValue: "Valoare achiziție",
  purchasePrice: "Preț achiziție",
  purchasePriceEuro: "Preț achiziție Euro",
  purchasePriceLei: "Preț achiziție Lei",
  salePriceValue: "Valoare vânzare",
  salePrice: "Preț vânzare",
  vehiclesNumber: "Numărul de vehicule",
  startDate: "Data de start",
  endDate: "Data de stop",
  vehicleStatus: "Status vehicul",
  purchaseType: "Tip achiziție",
  hideFilters: "Ascunde filtre",
  companies: "Companii",
  editeazaAcestvehicul: " editeaza acest vehicul de:",
  noAcces: "Nu ai acces sau aceasta pagina nu exista!",
  paginaNegasita: "Mergi la alta pagina!",
  editTVADeductibil: "NU POTI schimba TVA-UL cat timp ai TVA Recuperat Activ!",
  dashboardFinancial: "Departament financiar",
  shortDashboardFinancial: "Financiar",
  salesName: "Vanzari",
  acquisitionsName: "Achizitii",
  acquisition: "Achizitie",
  logisticsTitle: "Logistica",
  cursBNR: "Curs BNR",
  procentCursValutar: "Procent curs valutar",
  permisiuneDepartament: "Permisiune Departament",
  nonReserved: "Nerezervat",
  reservedTo: "Rezervat catre: ",
  generateContractMessage: "Generati contractul",
  paymentMethod: "Metoda de incasare",
  advance: "Avans",
  buyback: "Buy-back",
  buybackPrice: "Pret buyback",
  buybackBrand: "Brand buyback",
  buybackLicenseNumber: "Numar inmatriculare buyback",
  amountCredited: "Suma creditata",
  amount: "Suma",
  restOfPayment: "Rest de plata",
  technicalCondition: "Verificari stare tehnica",
  visibleOnSiteMessage:
    "Nu exista nici o dotare adaugata! Adauga dotari daca masina este vizibila pe site!",
  tipTVA: "Tip TVA",
  cursEuro: "Curs Euro",
  templatesName: "Șabloane",
  addTemplate: "Adauga template",
  deleteTemplateMessage: "Sunteti sigur ca doriti sa stergeti acest șablon?",
  deleteTemplate: "Ștergere șablon",
  documentName: "Nume document",
  startNumberDocument: "Numar document de start",
  documentType: "Tip document",
  nrRegCom: "Nr. Reg. Com.",
  number: "Numar CI",
  series: "Serie CI",
  priceWithoutTVA: "Pret fara TVA",
  valueWithoutTVA: "Valoare fara TVA",
  uploadDocuments: "Încarcă documente",
  uploadDocument: "Încarcă document",
  deschide: "Deschide",
  addDocument: "Adauga document",
  invoiceProvider: "Factura Furnizor",
  paymentOP: "Ordin de plata",
  clientInvoice: "Vânzare",
  bookingClient: "Rezervare client",
  generateProforma: "Generare Proforma",
  invoiceAndOP: "Facturi de avans si Ordin de plata de avans",
  contractAdvance: "Contractul de avans",
  completeContractData: "Completare date contract de vanzare",
  uploadClientInvoice: "Incarcare factura de vanzare",
  uploadContract: "Incarcare contract de vanzare semnat",
  uploadAdditionalPapers: "Incarcare acte aditionale",
  generateDocument: "Generează document",
  addInvoice: "Adaugă Factură",
  addOP: "Adaugă Ordin de Plată",
  leiPrice: "Pret in lei",
  lei: "LEI",
  euro: "Euro",
  noFile: "Nu exista fisier adaugat!",
  open: "Deschide",
  genereateAd: "Genereaza anunt",
  finished: "Finalizat",
  incomplete: "Incomplet",
  contractDataCompleted: "Datele din contract sunt completate!",
  contractDataNotCompleted:
    "Date din contract nu sunt completate sau sunt incorecte!",
  contractAdvanceIsNotExists: "Contractul de avans nu exista!",
  netPrice: "Pret net",
  TVAValue: "Valoare TVA",
  vehicleBrutPrice: "Pret vehicul brut",
  sellPriceNet: "Pret vanzare (net)",
  sellPriceBrut: "Pret vanzare (brut)",
  sellPrice: "Pret Vanzare",
  rate: "Dobanda",
  totalNET: "TOTAL (net)",
  brutPrice: "Pret brut",
  adminValidation: "Aprobare doar cu acordul administratorului!",
  currentExchangeRate: "Curs valutar existent ce se va modifica:",
  compulsory: "Obligatoriu",
  noFilesAdded: "Nu exista fisiere adaugate inca!",
  askForTVA: "Sunteti sigur ca doriti sa activati TVA Recuperat masina:",
  askForDiagnosis:
    "Solicită efectuarea unei diagnoze auto, pe propria cheltuială",
  doesNotAskForDiagnosis:
    "Nu solicită efectuarea unei diagnoze auto, pe propria cheltuială",
  close: "Inchide",
  financingMethod: "Modalitate finantare",
  newOption: "Optiune Noua",
  VATPercent: "Procent TVA",
  acquisitionNetPrice: "Pret Net Achizitie",
  nume: "Nume",
  judet: "Judet",
  telefon: "Telefon",
  adresa: "Adresa",
  valoare: "Valoare",
  askForDeleteAutocomplete: "Sunteti sigur ca doriti sa confirmati stergerea?",
  deleteSelectedLines: "Sterge liniile selectate",
  noData: "Nu exista date!",
  option: "optiune",
  optionsLowerCase: "optiuni",
  askBeforeSave: "Modifica inainte de salvare!",
  paymentMethods: "Modalitati plata",
  errorSettingPaymentMethods: "Eroare la impartirea modalitatilor de plata",
  observations: "Observatii",
  leasing: "Leasing",
  rezervedFor: "Rezervat pentru",
  atTheDate: "la data de",
  shippingAtTheDate: "alocat la data de",

  totalFunding: "Finanțare totală",
  deleteInstructions: "Selectare din lista pentru stergere!",
  notes: "Notite",
  serviceBook: "Carte Service",
  tuning: "Tuning",
  rightSideSteeringWheel: "Volan pe dreapta",
  equipment: "Dotari",
  vehicleInformation: "Informatii vehicul",
  administrativeInformation: "Informatii administrative",
  historicVehicle: "Retromobil",
  doesNotExist: "Nu exista",
  supplier: "Furnizor",
  priceOnWebsite: "Preț vechi",
  minimumSalePrice: "Preț actual",
  purchaseDate: "Data achizitie",
  receptionDate: "Data receptie",
  sellDate: "Data vanzare",
  addEquipment: "Adauga dotari",
  notifications: "Notificări",
  noNotifications: "Nu există notificări!",
  signedContract: "Contract semnat",
  contractData: "Informatii Contract",
  vin: "Serie sasiu (VIN)",
  acquisitionPrice: "Pret de achizitie",
  user: "Utilizator",
  TVA: "TVA",
  year: "Anul fabricarii",
  askBeforeDeleteVehicle: "Sunteti sigur ca doriti sa stergeti masina:",
  askForDeleteVehicle: "Sunteti sigur ca doriti sa stergeti masina?",
  recoveredTVA: "TVA Recuperat",
  searchResult: "Rezultate cautare:",
  generateDownPaymentProforma: "Generare proforma",
  netAmountInLei: "Valoare brut lei",
  generatedDocuments: "Documente generate",
  generics: "Generice",
  templateName: "Nume șablon",
  additionalData: "Date adiționale",
  emailTemplateName: "Nume șablon email",
  addDynamicData: "Adaugă date dinamic pentru subiect și corpul email-ului:",
  statusError: "Vă rugăm selectati un status",
  subject: "Subiect",
  send: "Trimite",
  attachment: "Atașament",
  to: "Către",
  cc: "CC",
  editor: "Edit",
  type: "Tip",
  financingRequests: "Cereri finantare",
  erase: "Șterge",
  ccMessage: "Introduceti adresele email-urilor separate prin virgulă",
  nume_firma: "Firma",
  allowBrowserNotifications: "Permiteți notificările browserului?",
  block: "Blochează",
  allow: "Permite",
  message: "Mesaj",
  clientMessages: "Mesaje clienti",
  vehicle: "Vehicul",
  oras: "Oras",
  purchaseUser: "Utilizator achizitie",
  logisticUser: "Utilizator logistica",
  expenses: "Cheltuieli",
  valuePerDay: "Valoare pe zi",
  sale: "Vanzare",
  commissionVolumeEstimate: "Comision volum estimat",
  commissionCAEstimate: "Comision CA estimat",
  commissionProfitEstimate: "Comision profit estimat",
  howMuchMoneyCanYouEarn: "Cât de mult poți câștiga?",
  estimate: "Estimări",
  salePriceEuro: "Preț vânzare Euro",
  salePriceRon: "Preț vânzare Lei",
  askForDeleteGuarantees:
    "Sunteti sigur ca doriti sa stergeti intrarea in garantie:",
  askForDeleteFinancialDoc:
    "Sunteti sigur ca doriti sa stergeti documentul financiar in valoare de:",
  askForDeleteDetailing:
    "Sunteti sigur ca doriti sa stergeti interventia de Detailing in valoare de:",
  askForDeletePainting:
    "Sunteti sigur ca doriti sa stergeti interventia de Vopsitorie in valoare de:",
  askForDeleteService:
    "Sunteti sigur ca doriti sa stergeti interventia de Service in valoare de:",
  askForDeleteCost:
    "Sunteti sigur ca doriti sa stergeti interventia de Costul aditional in valoare de:",
  minLimit: "Introduceti minim 13 caractere",
  maxLimit: "Introduceti maxim 18 caractere",
  order: "Ordoneaza",
  asc: "Ascendent",
  desc: "Descendent",
  search: "Cauta",
  pret_recomandat: "Pret recomandat",
  comision: "Comision",
  controlPanel: "Panou control",
  dataPregatireVanzare: "Data pregatire vanzare",
  shipping: "Transport",
  addNewShipping: "Transport nou",
  drivers: "Soferi",
  phoneNumber: "Numar telefon",
  deleteShipping: "Sunteți sigur că doriti sa stergeti transportul?",
  bookingShipping: "Rezervare transport",
  completeShipping: "Finalizare transport",
  shippingRezervedFor: "Transportat de catre",
  carrier: "Transportator",
  reserveCarrier: "Rezerva transport",
  noShipping: "Nu e rezervat pentru transport",
  noShipping5_2: "Masina nu a fost ridicata!",
  pickupDate: "Data ridicare",
  estimatedArrivalDate: "Data estimativa receptie",
  buyBackLabel: "Buy Back",
  inStock: "In Stoc",
  onDemand: "La Comanda",
  myMessages: "Mesajele mele",
  nonAlocated: "Toate mesajele",
  messageAllocation: "Alocare mesaj",
  clientDirectMessage: "Scrie un mesaj direct catre client",
  whatsappMesajDirect: "Mesaj direct Whatsapp",
  templateWhatsapp: "Template pentru Whatsapp",
  addNewImages: "Adaugati imagini noi",
  confirmDeleteImages: "Sunteti sigur ca doriti sa stergeti aceste imagini?",
  addImages: "Adaugati imagini",
  deleteImages: "Stergeti imaginile",
  deleteFromSite: "Sterge de pe Site",
  addOnSite: "Adauga pe Site",
  allocated: "Alocate mie",
  unallocated: "Nealocate",
  unassigned: "Nealocat",
  county: "Localitate",
  budget: "Buget",
  details: "Detalii",
  commandSource: "Sursa comanda",
  addNewCarOrder: "Comandă nouă",
  addNewCarOrderTitle: "Adaugă comandă nouă",
  editCarOrder: "Editează comandă",
  assignToAUser: "Alocă unui utilizator",
  deleteCarOrder: "Șterge comandă",
  deleteCarOrderMessage:
    "Sunteți sigur că doriți să ștergeți această/aceste comandă/comenzi?",
  signGDPR: "Semnează acord GDPR",
  signedGDPR: "Acord GDPR semnat",
  GDPR: "Acord GDPR",
  downloadGDPR: "Descarcă GDPR",
  downloadExcel: "Descarcă Excel",
  sendToEmailGDPRDoc: "Trimite doc. pe email",
  downloadDocument: "Descarcă document",
  associatedVehicle: "Vehicul asociat",
  associateWithVehicle: "Asociază cu un vehicul",
  associatedVehicleMessage:
    "Sunteti sigur ca vreti sa asociati vehiculul acesta cu aceasta comanda?",
  passwordNoMatch: "Parolele nu coincid",
  myAccount: "Contul meu",
  alocatedFor: "Atribuit catre: ",
  userAndSeller: "Utilizator/ Vanzator",
  onDemandVehicles: "La comanda",
  priceSiteSell: "Pret vanzare site",
  confirmEditPhotosStatus: "Confirmare editare poze",
  confirmEditPhotosStatusMessage:
    "Aceasta actiune va modifica ordinea si vizibilitatea lor pe Site!",
  confirmEditPhotos:
    "Sunteti sigur ca doriti sa modificati statusurile acestor poze?",
  CIF: "CIF",
  regCom: "Nr. registru comert",
  legalRepresentative: "Reprezentant legal",
  editCompany: "Editeaza companie",
  addNewCompany: "Adauga companie noua",
  CNP: "CNP",
  seria: "Seria",
  logo: "Logo",
  nrDocumente: "Nr. documente",
  nrProforma: "Nr. Proforma",
  nrContract: "Nr. Contract",
  serieDocumente: "Serie documente",
  serieProforma: "Serie Proforma",
  serieContract: "Serie Contract",
  bank: "Banca",
  IBAN: "IBAN",
  withInvoice: "Cu factura existenta",
  withoutAdvance: "Fara avans",
  sellTypeOrReserve: "Tip Vanzare/Rezervare",
  deductibil: "Deductibil",
  marjaTVA: "Marja TVA",
  contractExist: "Contract existent",
  withoutContract: "Fara contract",
  cancelSell: "Anulare vanzare",
  uploadGDPR: "Încarcă GDPR",
  confirm: "Confirma",
  canceled: "Anulate",
  automatedClientMessage: "Gestionare automata mesaje clienti",
  all: "Toate",
  cancelMessage:
    "Esti sigur ca vrei sa anulezi mesajul? Mesajul va trece automat in TAB-ul de mesaje ANULATE!",
  usersForClientMessages: "Ultizatori alocati pentru Mesaje Clienti",
  usersForFinancingRequests: "Ultizatori alocati pentru Cereri Finantare",
  usersForOnDemandsCars: "Ultizatori alocati pentru Masini la comanda",
  maxNumberForMessages: "Numar maxim alocare Mesaje",
  invalidDate: "Data invalida",
  tvaRecovered: "TVA Recuperat",
  info: "Informații",
  documents: "Documente",
  uploadDoc: "Incarca Documente",
  upload: "Incarca",
  seeTableTooltip: "Vezi documentele incarcate",
  generateTooltip: "Genereaza documente predefinite",
  allFiles: "Toate fisierele",
  generate: "Generează",
  noteMessage: "Adauga o notita",
  anafTable: "Tabelul ANAF",
  noDays: "Numar de zile",
  uit: "UIT",
  trTara: "Tara transport",
  nrVehicul: "Nr. Vehicul",
  dataTransport: "Data Transport",
  dataCreare: "Data Creare",
  errorMessageTransport: "Mesaj de eroare",
  subcontractor: "Subcontractor",
  vehiclesSoldByManagedUsers:
    "Mașini vândute de utilizatorii pe care îi administrează",

  //// PERMISIUNI (nu schimba denumirea de baza/cheia)
  anafNotifications: "Acces la Notificari ANAF",
  vehicleDashboards: "Acces la dashboard Vehicul",
  vehicleDetails: "Acces la detaliile despre Vehicul",
  vehicleGuarantees: "Acces la tabelul de Intrari Garantie ",
  vehicleDetailings: "Acces la tabelul de Detailing ",
  vehiclePaintings: "Acces la tabelul de Vopsitorie",
  vehicleCosts: "Acces la tabelul de Costuri aditionale",
  vehicleServices: "Acces la tabelul de Intrare in Service",
  vehicleDocuments: "Acces la tabelul de Documente",
  vehiclePhotos: "Acces la Fotografii",
  editVehicles: "Acces la Editare Vehicule",
  deleteVehicles: "Acces la Stergere Vehicule",
  clients: "Acces la tabelul cu Clienti",
  users: "Acces la tabelul cu Utilizatori",
  tasks: "Acces la tabelul cu Taskuri",
  reports: "Acces la tabelul cu Rapoarte",
  costs: "Acces la tabelul cu Costuri",
  logs: "Acces la tabelul cu Loguri",
  templates: "Acces la tabelul cu Sabloane",
  clientsMessages: "Acces la tabelul cu Mesaje",
  platformSettings: "Acces la Setarile Platformei",
  statusSettings: "Acces la setari pentru Statusuri Vehicule",
  rolesSettings: "Acces la setari pentru Roluri",
  permissions: "Acces la setari pentru Permisiuni",
  editStatus: "Acces la editare Statusuri",
  acquisitions: "Departament Achizitii",
  financialDepartment: "Departament Financiar",
  logistics: "Departament Logistica",
  sales: "Departament Vanzari",
  vehiclesDashboard: "Acces la tabelul principal cu Vehicule",
  fillInRequiredFields: "Completati campurile obligatorii!",
  profitEstimate: "Estimeaza profit",
  calculate: "Calculează",
  profit: "Profit",
  selectExcelTabs: "Selectați câmpurile din excel",
  history: "Istoric ",
  clientMessageWasAllocated: "Mesajul a fost alocat utilizatorului ",
  clientMessageStatusChanged: "Statusul mesajului a fost schimbat din ",
  clientMessageSetStatus: "Mesajul a primit un nou status: ",
  updatedBy: "Actualizat de: ",
  financialRequestWasAllocated:
    "Cererea de fianțare a fost alocată utilizatorului ",
  financialRequesStatusChanged:
    "Statusul cererii de fianțare a fost schimbat din ",
  financialRequesSetStatus: "Cererea de finanțare a primit un nou status: ",
  in: " în ",
  createdCarOrder: "Comanda a fost creată",
  budgetArticulated: "Bugetul",
  carOrder: "comandă",
  financingRequest: "cerere de finanțare",
  brandChanged: "Marca a fost schimbată din ",
  detailsChanged: "Detaliile au fost schimbate din ",
  emailChanged: "Email-ul a fost schimbat din ",
  cityChanged: "Localitatea a fost schimbată din ",
  modelChanged: "Modelul a fost schimbat din ",
  nameChanged: "Numele cumpărătorului a fost schimbat din ",
  carOrderStatusChanged: "Statusul comenzii a fost schimbat din ",
  carOrderSetStatus: "Comanda a primit un nou status: ",
  phoneChanged: "Telefonul a fost schimbat din ",
  associatedUserChanged: "Comanda a fost asociată utilizatorului ",
  budgetChanged: "Bugetul a fost schimbat din ",
  sourceChanged: "Sursa comenzii a fost schimbată din ",
  setAssociatedVehicle: "Comenzii i-a fost asociat vehiculul cu VIN ",
  setAssociatedBrand: "Marca a fost setată ",
  setAssociatedModel: "Modelul a fost setat ",
  setAssociatedUser: "Comanda a fost alocată utilizatorului ",
  setUser: "A fost asociat utilizatorului ",
  systemAssociated: "Actualizat automat",
  financingRequestCreated: "Cererea de finanțare a fost creată",
  clientMessageCreated: "Mesajul a fost creat",
  brandChangedDefault: "A fost schimbat brand-ul",
  modelChangedDefault: "A fost schimbat modelul",
  statusChangedDefault: "A fost schimbat statusul",
  userChangedDefault: "A fost asociat unui utilizator",
  vehicleChangedDefault: "A fost asociat un vehicul",
  associatedUserRemoved: "Utilizatorul alocat a fost dealocat",
  financingPeriod: "Perioada de finanțare",
  manualCarOrder: "Comandă făcută manual",
  siteCarOrder: "Comandă făcută de pe site",
  months: " luni",
  incompleteVehicles: "Vehicule fără opțiuni selectate",
  postAd: "Postează mașina pe alte site-uri",
  areYouSureText: "Sunteți sigur că doriți să postați mașina pe ",
  adWasPosted: "Anunțul a fost transmis către ",
  websitesIntegration: "Integrări",
  advertLink: "Link-ul către anunț: ",
  selectColumns: "Selectează coloanele",
  viewColumns: "Afișare coloane",
  source: "Sursă",
  mobileCarOrderSource: "Comandă pe site(verde)/manual(gri)",
  latestCars: "Ultimele mașini adăugate",
  departments: "Departamente",
  notificationType: "Tip notificare",
  deleteNotification:
    "Sunteți sigur că doriți să ștergeți tipul de notificare?",
  markAsRead: "Marchează toate ca citite",
  etransport: "E-Transport",
  integrareTransport:
    "Sunteți sigur că doriți să integrați compania cu E-Transport ?",
  ntVehicul: "Nr. Vehicul",
  nrRemorca: "Nr Remorca",
  anafTitle: "E-Transport",
  anafNotificationTitle: "Notificare ANAF",
  editAnafNotificationTitle: "Editare notificare ANAF",
  anafNotificationMessage:
    "Sunteți sigur că doriți să trimiteți notificarea la ANAF ?",
  bunuriTransport: "Bunuri Transport",
  scopOperatiune: "Scopul operatii",
  categorieBunuri: "Categorie bunuri",
  denumireBunuri: "Denumire bunuri",
  cantitate: "Cantitate",
  unitateMasura: "Unitate de masura",
  valoareLeiFaraTva: "Valoare lei fara TVA",
  greutateBruta: "Greutate Bruta(Kg)",
  greutateNeta: "Greutate Neta(Kg)",
  codScopOperatiune: "Scopul operatiunii",
  partenerComercial: "Partener comercial",
  denumirePartenerUE: "Denumire partener",
  statMembruUE: "Tara partener",
  dateTransport: "Date Transportator",
  nrInmatriculareVehiculCapTractor: "Nr. inmatriculare vehicul (Cap tractor)",
  taraTransportor: "Tara Transportor",
  denumireTransportor: "Denumire Transportor",
  dateDeIntrarePrinPTF: "Data de intrare prin PTF",
  ptfIntrare: "PTF intrare",
  locDescarcare: "Loc de descarcare",
  denumire: "Denumire",
  documenteTransport: "Documente Transport",
  tipDocument: "Tip Document",
  sterge: "Sterge",
  numarDocument: "Numar Document",
  dataDocument: "Data Document",
  uitValue: " | UIT ",
  Trimester: "Trimestrul",
  profitCommission: "Comision profit",
  CACommission: "Comision CA",
  targetCommision: "Comision Volum",
  minimumCommission: "Comision minim de vânzare",
  salaryConfiguration: "Setări salariu",
  sellersCommission: "Comision vânzători",
  acquisitionsCommission: "Comision achiziții",
  logisticsCommission: "Comision logistică",
  baseNetSalary: "Salariul net de bază",
  vehiclesWithNegativeProfit: "Vehicule cu profit negativ",
  vehicleSellingBonus: "Bonus vânzare vehicul",
  manageOtherUsers: "Administrează alți utilizatori",
  negativeProfitSoldCars: "Mașini vândute cu pierdere",
  positiveProfitSoldCars: "Mașini vândute cu profit pozitiv",
  remainedToSell: "Mașini rămase de vândut",
  realized: "Realizat",
  target: "Target",
  totalCommission: "Comision Total",
  bonuses: "Bonusuri",
  bonusLeasing: "Bonus de la Asiguratori si Leasing",
  otherBonuses: "Alte bonusuri",
  mealTickets: "Bonuri de masă",
  downloadSalaryExcel: "Descarcă fișierul de salariu",
  taxForLeasingBonus: "Taxe bonus de la asiguratori",
  usersSalary: "Acces la salarii",
  salaries: "Salarii",
  salary: "Salariu",
  month: "Luna",
  yearName: "Anul",
  generalTax: "Taxe generale",
  tax: "Impozit",
  totals: "Totaluri",
  sumCommissions: "Suma comisioane",
  commissionsTax: "Taxe comisioane",
  netCommissions: "Comision Net",
  salaryTotal: "Total Venit",
  profitFromOtherUsers: "Management utilizatori",
  standardCommission: "Comision Standard",
  soldCommission: "Comision Realizat",
  monthlyProfit: "Profit pe luna curentă",
  purchaseTypeCommission: "Comision Vehicule la Comandă",
  tipOperatiuneAIC: "AIC (Achizitie intracomunitara)",
  codPartener: "Cod partener",
  codOrgTransport: "Cod org. transport",
  startTraseu: "Start traseu",
  sfarsitTraseu: "Sfarsit traseu",
  codPostal: "Cod postal",
  alteInformatii: "Alte informatii privind adresa",
  numar: "Numar",
  managerCommission: "Comision Manager Departament",
  totalProfit: "Profit Total Utilizatori",
  sellingAndLogisticsCommission: "Comision achiziții și logistică",
  userIntermediere: "Utilizator intermediere",
  intermediatedVehicles: "Mașini intermediate",
  intermediated: "Intermediate",
  intermediatedVehiclesCommission: "Comision intermediere",
  initialTotalCommission: "Comision Total Initial",
  modifyBy: "Modificat de:",
  accesForAllMessages: "Acces la secțiunea TOATE a mesajelor",
  sellBonus: "Acces la campul de editare Bonus vanzare vehicul",
  seeGraphs: "Vizualizați grafice",
  exportFinancialSummary: "Descarcă detalii financiare",
  grossSalary: "Salariul brut",
  netSalary: "Salariul net",
  totalUserSalaries: "Totalul salariilor brute a utilizatorilor",
  socialInsurance: "Asigurari Sociale (CAS)",
  healthInsurance: "Asigurari Sociale de Sanatate (CASS)",
  incomeTax: "Impozit pe venit",
  autovit: "Autovit",
  bestauto: "Publi24",
  setAllVisibleOnSite: "Toate vizibile pe site",
  setAllHiddenOnSite: "Toate sterse pe site",
  docGenerated: "Document generat",
  succesSign: "semnat cu succes",
  sign: "Semnează",
  sendMail: "Trimite mail",
  cancelMail: "Anulează Email",
  newCar: "Mașină nouă",
  duplicateVehicle: "Acces la duplicarea unei mașini", //ptr permisiune
  duplicateVehicleButtonText: "Duplică",
  askForDuplicateVehicle: "Sunteți sigur că doriți să duplicați acest vehicul?",
  exportTable: "Exportă tabelul",
  changeStatusError:
    "Pentru a modifica data de vanzare sau vanzatorul, ambele campuri trebuie sa fie completate",
  penalty: "Penalizări",
  messages: "Mesaje",
  timeToReadMessage: "Citit în",
  timeToSeeMessage: "Văzut în",
  messageSeen: "Mesajul a fost vizualizat.",
  carOrderSeen: "Comanda a fost vizualizată.",
  saveAsImage: "Salvează ca imagine",
  soldCars: "Masini vandute",
  historyUser: "Istoric client",
  addPeriod: "Adaugă perioadă",
  comparePeriod: "Compară perioade",
  compareByDate: "Compară dupa dată",
  dropZoneText:
    "Trage și plasează fișierele aici sau fă clic pentru a selecta fișierele",
  publishOnSite: "Publică pe site",
  anafStergereNotificationTitle: "Doriti sa stergeti aceasta notificare?",
  vehicul: "Vehicul",
  selectedRows: "opțiuni selectate",
  autodelrulate: "Autodelrulate",
  sync: "Sincronizeaza",
  changeVehicleIcon: "Schimbă imaginea principală a vehiculului",
  dragAndDrop: "Trageți fișierul aici sau faceți clic pentru a încărca",
  fileSuccessfullyAdded: "Fișier adăugat cu succes.",
  fileSuccessfullyRemoved: "Fișier șters cu succes",
  completeVehicleDataMessage:
    "Te rugăm să salvezi vehiculul înainte de a adăuga o fotografie.",
  deleteUserMessage: "Sunți sigur că doriți să ștergeți acest utilizator?",
  editSoldVehicle: "Editează vehicule vandute",
  change: "Modifică",
  departmentsUsers: "Utilizatori din departamente",
  allDepartmentUsers: "Toți utilizatorii din departamente",
  showLess: "Aratați mai putin",
  download: "Descarcă",
  promoteAd: "Promovează anunțul pe site",
  promotedAt: "Promovat la: ",
  promoteVehicleWarning:
    "Vehiculul trebuie sincronizat pentru a fi promovat pe site.",
  totalProfitVehicles: "Profit Brut Total",
  positiveGrossProfitCount: "Vehicule cu Profit Pozitiv",
  positiveGrossProfit: "Profit Vehicule ",
  negativeGrossProfitCount: "Vehicule cu Pierderi",
  negativeGrossProfit: "Profit Vehicule cu Pierderi",
  loss: "Pierderi",
} as any;
