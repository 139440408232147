/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from "@mui/material";
import { urlEnum } from "../../Utils/urlEnum";
import { Vocabulary } from "../../Utils/Vocabulary";
import { VehicleFileType } from "../../Utils/autocompleteEnum";
import styles from "../../Styles/progressAction.module.css";

type ShowDocumentsListProps = {
  documents: Array<any>;
  types: Array<any>;
};


/**
 * A function to render a list of documents based on the provided props.
 *
 * It filters the documents based on the types provided in the props and maps over the filtered documents to render each document as a list element.
 * 
 * data-file is the props that does the magic, when it is set and the location changes we render the FilePreview component
 *
 * @param {ShowDocumentsListProps} props - The props object containing the documents and types to filter by.
 * @return {JSX.Element} The JSX element representing the list of documents.
 */
export function ShowDocumentsList(props: ShowDocumentsListProps) {
  const { documents, types } = props;

  /**
   *
   * @param element
   * @returns
   */
  function getDataFile(element: any) {
    switch (element?.type) {
      case VehicleFileType.contractDraft:
      case VehicleFileType.contractAdvanceDraft:
      case VehicleFileType.proforma:
      case VehicleFileType.proformaLeasing:
      case VehicleFileType.proformaFinancing:
        return `${urlEnum.templates}/getDoc/${element?.name}`;
      case VehicleFileType.providerInvoice:
      case VehicleFileType.providerOP:
      case VehicleFileType.clientInvoice:
      case VehicleFileType.contract:
      case VehicleFileType.contractAdvance:
      case VehicleFileType.amendment:
      case VehicleFileType.invoice:
      case VehicleFileType.OP:
        return `/api/${element?.file}`;
      default:
        return `/api/${element?.file}`;
    }
  }

  return (
    <>
      {documents && documents?.length !== 0 ? (
        documents
          .filter(function (item: any) {
            for (const key in types) {
              if (item.type === types[+key]) return true;
            }
            return false;
          })
          .map((element: any, index: number) => (
            <div
              key={`${element.name}-${element.type}`}
              className={styles.listElement}
            >
              <Typography
                data-file={getDataFile(element)}
                style={{
                  wordBreak: "break-all",
                  padding: 10,
                  cursor: "pointer",
                }}
              >
                {element?.name}
              </Typography>
              <Typography
                style={{
                  wordBreak: "break-all",
                  padding: 10,
                  cursor: "pointer",
                  fontWeight: 600,
                }}
              >
                {element.type}
              </Typography>
            </div>
          ))
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#f6f6f6",
            borderRadius: 10,
            marginLeft: 2,
            margin: 10,
            padding: 10,
          }}
        >
          <Typography>{Vocabulary.noFilesAdded}</Typography>
        </div>
      )}
    </>
  );
}
