/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import { StatusData } from "./Status";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import { urlEnum } from "../../Utils/urlEnum";
import GenericModal from "../../Modal/GenericModal";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  Autocomplete,
  Button,
  Grid,
  InputLabel,
  Paper,
  TextField,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  CarOrderStatus,
  ClientMessagesStatus,
  VehiclesStatus,
  tipStatusVehicule,
} from "../../Utils/autocompleteEnum";
import { SketchPicker } from "react-color";
import { isMobile } from "../../Utils/utils";
import { CacheContext } from "../../Context/cacheContext";

type StatusProps = {
  open: boolean;
  title: string;
  editedStatus: StatusData | null;
  onClose: (shouldRefetch?: boolean) => void;
};

const initialState: StatusData = {
  nume: "",
  culoare: "#FFF",
  tip_status: "",
};
export default function AddNewStatus(props: StatusProps) {
  const { open, title, editedStatus, onClose } = props;
  const cacheContext = useContext(CacheContext);
  const [data, setData] = useState<StatusData>(initialState);
  const [disabled, setDisabled] = useState(false);
  const [tipStatus, setTipStatus] = useState<any>({
    id: "",
    nume: "",
  });
  // const isDisabled = () => {
  //   switch (data.nume) {
  //     case VehiclesStatus.sold:
  //     case VehiclesStatus.reserved:
  //     case VehiclesStatus.received:
  //     case VehiclesStatus.advancePaid:
  //     case VehiclesStatus.contractToSign:
  //     case VehiclesStatus.paid:
  //     case VehiclesStatus.unpaid:
  //     case VehiclesStatus.canceled:
  //     case ClientMessagesStatus.canceled:
  //     case ClientMessagesStatus.unread:
  //       return true;
  //     default:
  //       return false;
  //   }
  // };

  useEffect(() => {
    if (editedStatus) {
      setData(editedStatus);
      const newTipStatus = tipStatusVehicule.find(
        (item) => item.nume === editedStatus.tip_status
      );
      setTipStatus(newTipStatus);

      //check if disabled
      switch (editedStatus.nume) {
        case VehiclesStatus.sold:
        case VehiclesStatus.reserved:
        case VehiclesStatus.received:
        case VehiclesStatus.advancePaid:
        case VehiclesStatus.contractToSign:
        case VehiclesStatus.paid:
        case VehiclesStatus.unpaid:
        case VehiclesStatus.canceled:
        case ClientMessagesStatus.canceled:
        case ClientMessagesStatus.unread:
        case CarOrderStatus.canceled:
        case CarOrderStatus.unread:
          setDisabled(true);
          break;
        default:
          setDisabled(false);
          break;
      }
    } else {
      setData(initialState);
      setTipStatus({
        id: "",
        nume: "",
      });
      setDisabled(false);
    }
  }, [editedStatus]);

  /**
   *
   * @param event
   */
  function handleChangeTextFields(event: any) {
    setData({ ...data, [event.target.name]: event.target.value });
  }

  /**
   *
   */
  function handleChangeStatusType(event: any, newValue: any) {
    const newData = Object.assign({}, data);
    if (newValue) {
      setTipStatus(newValue);
      newData.tip_status = newValue.nume;
    } else {
      setTipStatus({
        id: "",
        nume: "",
      });
      newData.tip_status = "";
    }
    setData(newData);
  }

  /**
   * Save status
   */
  function saveStatus() {
    if (editedStatus) {
      updateData(`${urlEnum.status_id}/${editedStatus.id}`, data)
        .then((response) => {
          setData(initialState);
          cacheContext.setCache({
            ...cacheContext.cache,
            vehicleStatus: response.data.vehicleStatus,
            templatesStatus: response.data.templatesStatus,
            clientMessagesStatus: response.data.clientMessagesStatus,
            carOrdersStatus: response.data.carOrdersStatus,
          });
          onClose(true);
        })
        .catch((error) => {
          setData(initialState);
          onClose(false);
        });
    } else {
      postData(urlEnum.status_id, data)
        .then((response) => {
          setData(initialState);
          cacheContext.setCache({
            ...cacheContext.cache,
            vehicleStatus: response.data.vehicleStatus,
            templatesStatus: response.data.templatesStatus,
            clientMessagesStatus: response.data.clientMessagesStatus,
            carOrdersStatus: response.data.carOrdersStatus,
          });
          onClose(true);
        })
        .catch((error) => {
          setData(initialState);
          onClose(false);
        });
    }
    setTipStatus({
      id: "",
      nume: "",
    });
  }

  return (
    <GenericModal
      open={open}
      onClose={() => {
        onClose(false);
        setData(initialState);
        setTipStatus({
          id: "",
          nume: "",
        });
      }}
      title={title}
    >
      <ValidatorForm onSubmit={saveStatus}>
        <Grid container spacing={isMobile() ? 1 : 4} style={{ marginTop: 5 }}>
          <Grid item md={6} xs={12}>
            <TextValidator
              name="nume"
              label={Vocabulary.name}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={data.nume}
              onChange={handleChangeTextFields}
              disabled={disabled}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete
              id="tip_status"
              freeSolo={false}
              disablePortal
              getOptionLabel={(option: any) => option.nume}
              size="small"
              value={tipStatus}
              options={tipStatusVehicule}
              onChange={(event: any, newValue: any) =>
                handleChangeStatusType(event, newValue)
              }
              sx={{ width: "100%" }}
              disabled={disabled}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Vocabulary.statusType}
                  variant="outlined"
                  size="medium"
                  disabled={disabled}
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <InputLabel style={{ margin: "10px 0px", fontSize: 15 }}>
              {Vocabulary.chooseColor}
            </InputLabel>
            <div
              style={{
                display: "flex",
              }}
            >
              <SketchPicker
                color={data.culoare}
                onChangeComplete={(color: any) =>
                  setData({ ...data, culoare: color.hex })
                }
              />
              <Paper
                style={{
                  width: 50,
                  height: 50,
                  margin: "0px 30px",
                  backgroundColor: data.culoare,
                }}
              ></Paper>
            </div>
          </Grid>
          <Grid
            container
            spacing={isMobile() ? 1 : 4}
            style={{ margin: 10, marginTop: 10 }}
          >
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => onClose(false)}
                fullWidth
              >
                {Vocabulary.cancel}
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                variant="contained"
                fullWidth
                type="submit"
                style={{ color: "white" }}
              >
                {Vocabulary.save}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </GenericModal>
  );
}
