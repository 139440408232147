/* eslint-disable @typescript-eslint/no-explicit-any */

import VehicleSideInfo from "../VehicleSideInfo";
import VehicleStatus from "../VehicleStatus";
import { Grid, Typography } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TotalCosts from "./TotalCosts";
import { isMobile } from "../../Utils/utils";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SaleDuration from "./SaleDuration";
import withRole from "../../Utils/withRole";
import ProgressAction1 from "../ProgressActions/ProgressAction1";
import ProgressAction2 from "../ProgressActions/ProgressAction2";
import ProgressAction3 from "../ProgressActions/ProgressAction3";
import ProgressAction4 from "../ProgressActions/ProgressAction4";
import ProgressAction5 from "../ProgressActions/ProgressAction5";
import { useContext, useMemo } from "react";
import { VehicleContext } from "../../Context/vehicleContext";
import { Vocabulary } from "../../Utils/Vocabulary";
import CancelSell from "../ProgressActions/CancelSell";
import { CacheContext } from "../../Context/cacheContext";
import { VehiclesStatus } from "../../Utils/autocompleteEnum";
import { RolesEnum } from "../../Utils/Constants";
// import { CacheContext } from "../../Context/cacheContext";

function VehiclesDashboard(props: any) {
  const vehicleContext: any = useContext(VehicleContext); //TODO
  const cacheContext: any = useContext(CacheContext);

  const soldStatusID = useMemo(() => {
    const sold = cacheContext.cache.vehicleStatus.find(
      (item: any) => item.nume === VehiclesStatus.sold
    );
    return sold.id ? sold.id : 0;
  }, [cacheContext.cache.vehicleStatus]);
  const roles = localStorage.getItem("roles") || "";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#F7F8FA",
        height: "100%",
      }}
    >
      <ToastContainer />
      <div
        style={{
          display: "flex",
        }}
      >
        <div
          id={"dashboardVehicle"}
          style={{
            borderRadius: 10,
            margin: "10px 0px 10px 0px",
            padding: 18,
            backgroundColor: "#fff",
            overflowY: "auto",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: isMobile() ? "column" : "row",
            }}
          >
            <div style={{ width: "100%" }}>
              <div
                style={
                  {
                    // pointerEvents: props?.info?.includes(5) ? "inherit" : "none",
                    // cursor: props?.info?.includes(5) ? "pointer" : "not-allowed",
                  }
                }
              >
                <VehicleStatus />
              </div>
              {isMobile() ? (
                <Accordion
                  style={{
                    zIndex: 10,
                    marginBottom: 10,
                    marginTop: 15,
                    color: "#fff",
                    borderRadius: 5,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ backgroundColor: "#f63" }}
                  >
                    <Typography style={{ marginTop: 1 }}>
                      Status financiar
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      style={{
                        marginTop: 20,
                        paddingRight: 0,
                        display: "flex",
                        gap: 10,
                        flexDirection: isMobile() ? "column" : "row",
                      }}
                    >
                      {props?.info?.includes(1) ? <ProgressAction1 /> : null}
                      {props?.info?.includes(2) ? <ProgressAction2 /> : null}
                      {
                        // props?.info?.includes(0) &&  //DACA DECOMENTEZI ASTA, DOAR DEPARTAMENTUL ACHIZITII O SA AIBA ACCESS LA TRANSPORT (DECOMENTEAZA CODUL SI DE MAI JOS - aici e doar pt mobil)
                        vehicleContext?.state?.vehicle?.tip_achizitie_r
                          ?.nume !== Vocabulary.buyBackLabel ? (
                          <ProgressAction5 />
                        ) : null
                      }
                      {props?.info?.includes(3) ? <ProgressAction3 /> : null}
                      {props?.info?.includes(4) ? <ProgressAction4 /> : null}
                      <>
                        {vehicleContext.state.vehicle?.status_id ===
                        soldStatusID ? (
                          <>
                            {roles.includes(RolesEnum.admin) ? (
                              <CancelSell />
                            ) : null}
                          </>
                        ) : null}
                      </>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ) : (
                <div
                  style={{
                    display: "flex",
                    gap: 15,
                    flexDirection: "column",
                    margin: 10,
                  }}
                >
                  {props?.info?.includes(1) ? <ProgressAction1 /> : null}
                  {props?.info?.includes(2) ? <ProgressAction2 /> : null}
                  {
                    // props?.info?.includes(0) && //DACA DECOMENTEZI ASTA, DOAR DEPARTAMENTUL ACHIZITII O SA AIBA ACCESS LA TRANSPORT (DECOMENTEAZA CODUL SI DE MAI SUS - aici e doar pt DISPLAY-uri mari)
                    vehicleContext?.state?.vehicle?.tip_achizitie_r?.nume !==
                    Vocabulary.buyBackLabel ? (
                      <ProgressAction5 />
                    ) : null
                  }
                  {props?.info?.includes(3) ? <ProgressAction3 /> : null}
                  {props?.info?.includes(4) ? <ProgressAction4 /> : null}

                  <>
                    {vehicleContext.state.vehicle?.status_id ===
                    soldStatusID ? (
                      <>
                        {roles.includes(RolesEnum.admin) ? (
                          <>
                            <CancelSell />
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                </div>
              )}
              <div>
                {isMobile() ? (
                  <Accordion
                    style={{
                      zIndex: 10,
                      marginBottom: 10,
                      marginTop: 20,
                      color: "#fff",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      style={{ backgroundColor: "#f63" }}
                    >
                      <Typography style={{ marginTop: 1 }}>
                        Detalii autovehicul
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <VehicleSideInfo />
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <div style={{ marginTop: 20 }}>
                    <VehicleSideInfo />
                  </div>
                )}
              </div>
              <SaleDuration />

              <Grid item xs={12} style={{ marginBottom: 20 }}>
                <TotalCosts />
              </Grid>
              {/* <ProfitTab></ProfitTab> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRole(VehiclesDashboard);
