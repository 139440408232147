import { Divider, Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import style from "../../Styles/optionsVehicle.module.css";

export type SalaryTotalsProps = {
  settings: any;
  // baseNetSalary: string;
  commissionsSum: number;
  // otherBonuses: number;
  generalTax: number;
  ///leasingBonus: number;
  //mealTickets: number;
  userData: any;
  intermediatedVehiclesCommission: number;
  sellingLogisticsCommission: number;
  isMarketing: boolean;
  monthlyProfit: string;
  vehiclesPurchaseTypeCommission: number;
  sellingCommission: number;
  profitFromOtherUsers: number;
  marketingCommission: number;
};

export default function SalaryTotals(props: SalaryTotalsProps) {
  const {
    settings,
    userData,
    commissionsSum,
    generalTax,
    sellingLogisticsCommission,
    isMarketing,
    vehiclesPurchaseTypeCommission,
    intermediatedVehiclesCommission,
    sellingCommission,
    profitFromOtherUsers,
    marketingCommission,
  } = props;

  const numberFormat = new Intl.NumberFormat();
  const leasingTax = settings?.filter(
    (setting: typeof settings) => setting.slug == "taxForLeasingBonus"
  );

  /* CALCULE */
  const totalCommission =
    commissionsSum +
    Number(userData?.otherBonuses) +
    vehiclesPurchaseTypeCommission -
    userData?.mealTickets;
  const taxForCommissions = userData?.isSubcontractor //daca e subcontractor, nu se calculeaza taxe
    ? 0
    : ((totalCommission * generalTax) / 100).toFixed(2);
  const netCommission = totalCommission - Number(taxForCommissions);
  const taxForLeasingBonus = userData?.isSubcontractor //daca e subcontractor, nu se calculeaza taxe
    ? 0
    : (userData?.leasingBonus * leasingTax[0]?.value) / 100;
  const netLeasingBonus = userData?.leasingBonus - taxForLeasingBonus;
  const total = Number(
    (netCommission + netLeasingBonus + profitFromOtherUsers).toFixed(2)
  );

  /* marketing */
  const totalMarketingCommission =
    marketingCommission +
    Number(userData?.otherBonuses) -
    userData?.mealTickets;
  const marketingCommissionTax = Number(
    ((totalMarketingCommission * generalTax) / 100).toFixed(2)
  );
  const netMarketingCommission =
    totalMarketingCommission - marketingCommissionTax;
  const totalMarketing = netMarketingCommission + netLeasingBonus;
  /**
   *
   * @param key
   * @param value
   * @returns
   */
  const renderElement = (
    key: string,
    value: string | number,
    highlighted = false
  ) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography>{key}</Typography>
        <Typography
          className={highlighted ? style.highlightedLabels : style.labels}
        >
          {value} Lei
        </Typography>
      </div>
    );
  };

  /**
   *
   * @returns
   */
  const renderMarketingSalary = () => {
    return (
      <>
        {renderElement(Vocabulary.otherBonuses, userData?.otherBonuses)}
        {renderElement(
          Vocabulary.comision,
          numberFormat.format(marketingCommission)
        )}
        {renderElement(
          Vocabulary.mealTickets,
          ` - ${numberFormat.format(userData?.mealTickets)}`
        )}
        {renderElement(
          Vocabulary.commissionsTotals,
          numberFormat.format(totalMarketingCommission)
        )}
        {renderElement(
          `${Vocabulary.commissionsTax} ( ${generalTax}% )`,
          `- ${numberFormat.format(marketingCommissionTax)}`
        )}

        {renderElement(
          Vocabulary.netCommissions,
          numberFormat.format(netMarketingCommission)
        )}
        {renderElement(
          Vocabulary.bonusLeasing,
          numberFormat.format(userData?.leasingBonus)
        )}
        {userData?.leasingBonus != 0 && taxForLeasingBonus != 0 && (
          <>
            {renderElement(
              `${Vocabulary.taxForLeasingBonus} ( ${leasingTax[0]?.value} %)`,
              `- ${numberFormat.format(taxForLeasingBonus)}`
            )}
            {userData?.leasingBonus != 0 &&
              renderElement(
                `${Vocabulary.bonusLeasing} Net`,
                numberFormat.format(netLeasingBonus)
              )}
          </>
        )}

        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
        {renderElement(Vocabulary.total, numberFormat.format(totalMarketing))}
        {renderElement(
          Vocabulary.baseNetSalary,
          numberFormat.format(Number(userData?.baseNetSalary))
        )}
        {renderElement(
          Vocabulary.salaryTotal,
          numberFormat.format(Number(userData?.baseNetSalary) + totalMarketing),
          true
        )}
      </>
    );
  };
  return (
    <>
      <fieldset style={{ borderRadius: 7 }}>
        <legend>
          <Typography style={{ padding: 3 }}>{Vocabulary.totals}</Typography>
        </legend>
        {isMarketing ? (
          renderMarketingSalary()
        ) : (
          <>
            {sellingCommission != 0 && (
              <>
                {renderElement(
                  Vocabulary.comisionVanzare,
                  numberFormat.format(sellingCommission)
                )}
              </>
            )}
            {sellingLogisticsCommission != 0 && (
              <>
                {renderElement(
                  Vocabulary.sellingAndLogisticsCommission,
                  numberFormat.format(sellingLogisticsCommission)
                )}
              </>
            )}
            {intermediatedVehiclesCommission != 0 && (
              <>
                {renderElement(
                  Vocabulary.intermediatedVehiclesCommission,
                  numberFormat.format(intermediatedVehiclesCommission)
                )}
              </>
            )}
            {renderElement(
              Vocabulary.sumCommissions,
              numberFormat.format(commissionsSum)
            )}
            {renderElement(Vocabulary.otherBonuses, userData?.otherBonuses)}
            {vehiclesPurchaseTypeCommission != 0 && (
              <>
                {renderElement(
                  Vocabulary.purchaseTypeCommission,
                  numberFormat.format(vehiclesPurchaseTypeCommission)
                )}
              </>
            )}
            {renderElement(
              Vocabulary.mealTickets,
              ` - ${numberFormat.format(userData?.mealTickets)}`
            )}
            {renderElement(
              Vocabulary.commissionsTotals,
              numberFormat.format(totalCommission)
            )}
            {totalCommission != 0 && taxForCommissions != 0 && (
              <>
                {renderElement(
                  Vocabulary.commissionsTax + ` ( ${generalTax}% )`,
                  "- " + numberFormat.format(Number(taxForCommissions))
                )}
                {renderElement(
                  Vocabulary.netCommissions,
                  numberFormat.format(netCommission)
                )}
              </>
            )}
            {renderElement(
              Vocabulary.bonusLeasing,
              numberFormat.format(userData?.leasingBonus)
            )}
            {userData?.leasingBonus != 0 && taxForLeasingBonus != 0 && (
              <>
                {renderElement(
                  `${Vocabulary.taxForLeasingBonus} ( ${leasingTax[0]?.value} %)`,
                  `- ${numberFormat.format(taxForLeasingBonus)}`
                )}
                {userData?.leasingBonus != 0 &&
                  renderElement(
                    `${Vocabulary.bonusLeasing} Net`,
                    numberFormat.format(netLeasingBonus)
                  )}
              </>
            )}

            {profitFromOtherUsers != 0 &&
              renderElement(
                Vocabulary.profitFromOtherUsers,
                numberFormat.format(profitFromOtherUsers)
              )}
            <Divider style={{ marginTop: 5, marginBottom: 5 }} />
            {renderElement(Vocabulary.total, numberFormat.format(total))}
            {renderElement(
              Vocabulary.baseNetSalary,
              numberFormat.format(Number(userData?.baseNetSalary))
            )}
            {renderElement(
              Vocabulary.salaryTotal,
              numberFormat.format(Number(userData?.baseNetSalary) + total),
              true
            )}
          </>
        )}
      </fieldset>
    </>
  );
}
