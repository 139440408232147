/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import Reports from "../Components/Reports/Reports";
import AdministrativeCostsDashboard from "../Components/AdministrativeCosts/AdministrativeCostsDashboard";
import Negotiation from "../Components/Negotiation/Negotiation";
import Logs from "../Components/Logs/Logs";
import HeaderAndSlider from "../Components/HeaderAndSlider";
import UsersDashboard from "../Components/Users/UsersDashboard";
import TasksDashboard from "../Components/Tasks/TasksDashboard";
import MainSettings from "../Components/Settings/MainSettings";
import { Vocabulary } from "./Vocabulary";
import MainDashboard from "../Components/MainDashboard";
import VehiclesDashboard from "../Components/VehicleDasboard/VehiclesDashboard";
import VehicleDetails from "../Components/VehicleDetails";
import VehicleService from "../Components/VehicleService";
import VehicleDetailing from "../Components/VehicleDetailing";
import VehiclePainting from "../Components/VehiclePainting";
import VehicleAdditionalCost from "../Components/VehicleAdditionalCost";
import VehiclePhotos from "../Components/VehiclePhotos";
import VehicleDocuments from "../Components/VehicleDocuments";
import VehicleGuarantees from "../Components/VehicleGuarantees";
import LoginPage from "../Components/LoginPage";
import Clients from "../Components/Clients/Clients";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import withRole from "./withRole";
import { useEffect, useState } from "react";
import type { Router } from "@remix-run/router";
import NotFoundPage from "./NotFoundPage";
import { componentNames } from "./Constants";
import DashboardFinancial from "../Components/FinancialDepartment/DashboardFinancial";
import DashboardSales from "../Components/Sales/DashboardSales";
import DashboardAcquisitions from "../Components/Acquisitions/DashboardAcquisitions";
import DashboardLogistics from "../Components/Logistics/DashboardLogistics";
import MainTemplates from "../Components/Templates/MainTemplates";
import { localUrlEnum } from "./urlEnum";
import VehicleOptionSlider from "../Components/VehicleDasboard/VehicleOptionSlider";
import ControlPanel from "../Components/ControlPanel/ControlPanel";
import DashboardClientMessages from "../Components/ClientMessages/DashboardClientMessages";
import DashboardSalesOnDemand from "../Components/Sales/DashboardSalesOnDemand";
import DashboardFinancingMessages from "../Components/ClientMessages/DashboardFinancingMessages";
import AnafTable from "../Components/ANAF/AnafTable";
import Dashboard from "../Components/Dashboard/Dashboard";
import UserSalaries from "../Components/Salary/UserSalaries";

type LocalRouterProps = {
  routes: Array<string> | undefined;
};

function LocalRouter(props: LocalRouterProps) {
  const routerPaths = [
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/home",
      element: addHeaderAndMenu(<Dashboard />, Vocabulary.dashboard),
    },
    {
      path: "/vehiclesDashboard",
      element: addHeaderAndMenu(<MainDashboard />, Vocabulary.vehicles),
    },
    {
      path: "/financialDepartment",
      element: addHeaderAndMenu(
        <DashboardFinancial />,
        Vocabulary.dashboardFinancial
      ),
    },
    {
      path: "/sales",
      element: addHeaderAndMenu(<DashboardSales />, Vocabulary.inStock),
    },
    {
      path: "/onDemand",
      element: addHeaderAndMenu(
        <DashboardSalesOnDemand
          name={componentNames.DashboardClientMessages}
        />,
        Vocabulary.onDemand
      ),
    },
    {
      path: "/acquisitions",
      element: addHeaderAndMenu(
        <DashboardAcquisitions />,
        Vocabulary.acquisitionsName
      ),
    },
    {
      path: "/logistics",
      element: addHeaderAndMenu(
        <DashboardLogistics />,
        Vocabulary.logisticsTitle
      ),
    },
    {
      path: "/anaf/notifications",
      element: addHeaderAndMenu(<AnafTable />, Vocabulary.anafTable),
    },
    {
      path: "/controlPanel",
      element: addHeaderAndMenu(<ControlPanel />, Vocabulary.controlPanel),
    },

    {
      path: "/vehiclesDashboard/vehicleDashboard/:id?",
      element: addHeaderAndMenu(
        addVehicleOptionsMenu(
          <VehiclesDashboard name={componentNames.VehiclesDashboard} />,
          Vocabulary.vehicles
        ),
        Vocabulary.vehicles
      ),
    },
    {
      path: "/vehiclesDashboard/vehicleDetails/:id?",
      element: addHeaderAndMenu(
        addVehicleOptionsMenu(
          <VehicleDetails name={componentNames.VehicleMainInfo} />,
          Vocabulary.vehicles
        ),
        Vocabulary.vehicles
      ),
    },
    {
      path: "/vehiclesDashboard/vehicleGuarantees/:id?",
      element: addHeaderAndMenu(
        addVehicleOptionsMenu(<VehicleGuarantees />, Vocabulary.vehicles),
        Vocabulary.vehicles
      ),
    },
    {
      path: "/vehiclesDashboard/vehicleService/:id?",
      element: addHeaderAndMenu(
        addVehicleOptionsMenu(<VehicleService />, Vocabulary.vehicles),
        Vocabulary.vehicles
      ),
    },
    {
      path: "/vehiclesDashboard/vehicleDetailing/:id?",
      element: addHeaderAndMenu(
        addVehicleOptionsMenu(<VehicleDetailing />, Vocabulary.vehicles),
        Vocabulary.vehicles
      ),
    },
    {
      path: "/vehiclesDashboard/vehiclePainting/:id?",
      element: addHeaderAndMenu(
        addVehicleOptionsMenu(<VehiclePainting />, Vocabulary.vehicles),
        Vocabulary.vehicles
      ),
    },
    {
      path: "/vehiclesDashboard/vehicleAdditionalCost/:id?",
      element: addHeaderAndMenu(
        addVehicleOptionsMenu(<VehicleAdditionalCost />, Vocabulary.vehicles),
        Vocabulary.vehicles
      ),
    },
    {
      path: "/vehiclesDashboard/vehiclePhotos/:id?",
      element: addHeaderAndMenu(
        addVehicleOptionsMenu(<VehiclePhotos />, Vocabulary.vehicles),
        Vocabulary.vehicles
      ),
    },
    {
      path: "/vehiclesDashboard/vehicleDocuments/:id?",
      element: addHeaderAndMenu(
        addVehicleOptionsMenu(<VehicleDocuments />, Vocabulary.vehicles),
        Vocabulary.vehicles
      ),
    },

    {
      path: "/usersDashboard",
      element: addHeaderAndMenu(<UsersDashboard />, Vocabulary.usersName),
    },
    {
      path: "/clients",
      element: addHeaderAndMenu(<Clients />, Vocabulary.clientsName),
    },
    {
      path: "/negotiation",
      element: addHeaderAndMenu(<Negotiation />, Vocabulary.negotiation),
    },
    {
      path: "/TasksDashboard",
      element: addHeaderAndMenu(<TasksDashboard />, Vocabulary.tasksName),
    },
    {
      path: "/settings",
      element: addHeaderAndMenu(<MainSettings />, Vocabulary.settings),
    },
    {
      path: "/templates",
      element: addHeaderAndMenu(<MainTemplates />, Vocabulary.templatesName),
    },
    {
      path: "/salaries",
      element: addHeaderAndMenu(<UserSalaries />, Vocabulary.salaries),
    },
    {
      path: "/salaries/user/:id?/:month?/:year?",
      element: addHeaderAndMenu(<Dashboard name={componentNames.SalaryComponent}/>, Vocabulary.dashboard),
    },
    {
      path: "/reports",
      element: addHeaderAndMenu(<Reports />, Vocabulary.reportsName),
    },
    {
      path: localUrlEnum.clientMessages,
      element: addHeaderAndMenu(
        <DashboardClientMessages
          name={componentNames.DashboardClientMessages}
        />,
        Vocabulary.clientMessages
      ),
    },
    {
      path: localUrlEnum.financingRequests,
      element: addHeaderAndMenu(
        <DashboardFinancingMessages
          name={componentNames.DashboardClientMessages}
        />,
        Vocabulary.financingRequests
      ),
    },
    {
      path: "/administrativeCosts",
      element: addHeaderAndMenu(
        <AdministrativeCostsDashboard />,
        Vocabulary.costsName
      ),
    },
    {
      path: "/logs",
      element: addHeaderAndMenu(<Logs />, Vocabulary.logsName),
    },

    {
      path: "/",
      element: addHeaderAndMenu(<Dashboard name={componentNames.SalaryComponent}/>, Vocabulary.dashboard),
    },
    {
      path: "*",
      element: addHeaderAndMenu(<NotFoundPage />, Vocabulary.paginaNegasita),
    },
  ];

  const [router, setRouter] = useState<Router>();

  useEffect(() => {
    if (!props.routes) return;

    const newRouterPaths = routerPaths.filter((crtRoute) => {
      return props.routes && props.routes.indexOf(crtRoute.path) >= 0;
    });
    setRouter(createBrowserRouter(newRouterPaths));
  }, [props]);

  function addVehicleOptionsMenu(component: any, title: string) {
    return <VehicleOptionSlider title={title} component={component} />;
  }

  function addHeaderAndMenu(component: any, title: string) {
    return <HeaderAndSlider key={title} title={title} component={component} />;
  }

  return router && <RouterProvider router={router} />;
}

export default withRole(LocalRouter);
