/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ProgressActionWrapper from "../ProgressActionComponents/ProgressActionWrapper";
import ProgressAction from "../ProgressActionComponents/ProgressAction";
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/progressAction.module.css";
import {
  Autocomplete,
  Button,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { VehicleContext } from "../../Context/vehicleContext";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import EditIcon from "@mui/icons-material/Edit";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/urlEnum";
import formatDate, {
  anafFormatDate,
  europeanDate,
} from "../../Utils/formatDate";
import moment from "moment";
import { NotificareANAF } from "../../Models/Models";
import SubProgressInformation5_1Delete from "./ProgressInformation5/SubProgressInformation5_1Delete";
import SubProgressInformation5_1 from "./ProgressInformation5/SubProgressInformation5_1";
import SubProgressInformation5_2 from "./ProgressInformation5/SubProgressInformation5_2";
import GenericModal from "../../Modal/GenericModal";
import {
  containsLetters,
  disableTostifyConfig,
  formatNumberToEuropean,
  getCompanyInfo,
  isMobile,
} from "../../Utils/utils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DocumenteTransport from "./ANAF/Notificare/DocumenteTransport";
import DateTransport from "./ANAF/Notificare/DateTransport";
import BunuriTransport from "./ANAF/Notificare/BunuriTransport";
import PartenerComercial from "./ANAF/Notificare/PartenerComercial";
import { postData } from "../../Services/postData";
import LocDeStartFinalTraseu from "./ANAF/Notificare/LocDeStartFinalTraseu";
import { tipOperatiuni } from "../../Utils/Enums/TipOperatiune";
import { CacheContext } from "../../Context/cacheContext";
import { VehiclesStatus } from "../../Utils/autocompleteEnum";
import AnafNotification from "../ANAF/AnafNotification";
import { updateData } from "../../Services/updateData";

export default function ProgressAction5() {
  const vehicleContext: any = useContext(VehicleContext); //TODO
  const cacheContext = useContext(CacheContext) as any;
  const [shippings, setShippings] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [bunuriTransportate, setBunuriTransportate] = useState([]);
  const [unitateMasura, setUnitateMasura] = useState([]);
  const [openReserve, setOpenReserve] = useState(false);
  const [openReserve5_2, setOpenReserve5_2] = useState(false);
  const [openReserveDelete, setOpenReserveDelete] = useState(false);
  const [openAnafNotificationModal, setOpenAnafNotificationModal] = useState(
    false
  );
  const [openEditAnafNotification, setOpenEditAnafNotification] = useState(
    false
  );
  const [notificationForUpdate, setNotificationForUpdate]: any = useState(null);

  /**
   *
   */
  useEffect(() => {
    getShippings();
    getAllCompanies();
    getBunuriTransportate();
    getUnitateMasura();
  }, []);

  const { handleSubmit, control, setValue, watch, getValues } = useForm({
    resolver: yupResolver(NotificareANAF),
  });

  const watchDocumenteTransport = watch("documenteTransport");

  const watchCodOrganizatieTransportor = watch(
    "partenerComercial.codOrgTransport"
  );

  const getCompanyBasedOnCUIorName = (filter: string) => {
    return shippings.find((company: any) => {
      return company.CIF?.includes(filter) || company.name?.includes(filter);
    });
  };

  const setDateTransportor = (cod: any) => {
    const data = getValues("dateTransport");
    if (data) {
      getCompanyInfo(
        (containsLetters(cod) ? cod.substring(2) : cod) || "",
        (containsLetters(cod)
          ? cod.substring(0, 2)
          : data.codTaraOrgTransport) || ""
      ).then((res: any) => {
        if (res) {
          setValue("dateTransport.denumireOrgTransport", res.data.name);
        }
      });
    }
  };

  useEffect(() => {
    const data = getValues("partenerComercial");
    const furnizor_id = vehicleContext.state.vehicle?.furnizor_id;
    if (data && furnizor_id !== 3) {
      getCompanyInfo(
        (containsLetters(data.codOrgTransport || "")
          ? data.codOrgTransport?.substring(2)
          : data.codOrgTransport) || "",
        (containsLetters(data.codOrgTransport || "")
          ? data.codOrgTransport?.substring(0, 2)
          : data.codTara) || ""
      ).then((res: any) => {
        if (res && res.data.name !== "---") setValue("partenerComercial.denumire", res.data.name);
      });
    }
  }, [watchCodOrganizatieTransportor]);

  const deleteDocument = (index: number) => {
    const data = getValues("documenteTransport");
    data.splice(index, 1);

    setValue("documenteTransport", data);
  };

  const addDocument = () => {
    const data = getValues("documenteTransport");
    if (!data) {
      setValue("documenteTransport", [
        {
          tipDocument: "10",
          numarDocument: "",
          dataDocument: "",
          // observatii: "",
        },
      ]);
    } else {
      data.push({
        tipDocument: "10",
        numarDocument: "",
        dataDocument: "",
        // observatii: "",
      });
      setValue("documenteTransport", data);
    }
  };

  /**
   *
   * @param page
   * @param perPage
   * @param search
   */
  function getShippings() {
    const url = `${urlEnum.shipping}`;

    getData(url)
      .then((res: any) => {
        setShippings(res.data.shippings);
      })
      .catch((err) => {
        //
      });
  }

  function getBunuriTransportate() {
    const url = `${urlEnum.getBunuriTransportate}`;

    getData(url)
      .then((res: any) => {
        setBunuriTransportate(res.data.data);
      })
      .catch((err) => {
        //
      });
  }
  /**
   *
   */
  function getAllCompanies() {
    const url = `/${urlEnum.firma_achizitie_r}`;
    getData(url)
      .then((res) => {
        setCompanies(res.data.data);
      })
      .catch((err) => {
        //
      });
  }

  function getDocumenteTransport() {
    const data = getValues("documenteTransport");
    if (!data || data?.length === 0) {
      addDocument();
    }
    if (watchDocumenteTransport) {
      console.log();
    }
    const newDocs = getValues("documenteTransport");
    return newDocs;
  }

  /**
   *
   */
  function getUnitateMasura() {
    const url = `${urlEnum.unitateMasura}`;
    getData(url)
      .then((res) => {
        setUnitateMasura(res.data.data);
      })
      .catch((err) => {
        //
      });
  }

  /**
   *
   */
  const handleModalReserve = () => {
    setOpenReserve(!openReserve);
  };
  /**
   *
   */
  const handleModalReserve5_2 = () => {
    setOpenReserve5_2(!openReserve5_2);
  };
  /**
   *
   */
  const handleModalReserveDelete = () => {
    setOpenReserveDelete(!openReserveDelete);
  };

  const handleModalSendNotificationToANAF = () => {
    // check if it should be posible to open the modal
    const firma_achizitie_id = vehicleContext.state.vehicle?.firma_achizitie_id;
    // If it is AUTOBID
    const furnizor_id = vehicleContext.state.vehicle?.furnizor_id;
    if (furnizor_id === 3) {
      setValue("partenerComercial.codOrgTransport", "DE232442550");
      setValue("partenerComercial.codTara", "DE");
      setValue("partenerComercial.denumire", "Auktion & Markt AG");
    }
    const companieAchizitie: any = companies?.filter(
      (comp: any) => comp.id === firma_achizitie_id
    );
    if (companieAchizitie[0]?.access_token) {
      setOpenAnafNotificationModal(!openAnafNotificationModal);
    }
  };

  const onChangeADRJudet = (cod: number) => {
    const data = getValues("locatie");
    const newLocationObject = {
      codJudet: cod.toString(),
      orasLocalitate: cod === 33 ? "Suceava" : "",
      denumireLocalitate: "",
      denumireStrada: cod === 33 ? "Bulevardul Sofia Vicoveanca" : "",
      numar: cod === 33 ? "52" : "",
      codPostal: cod === 33 ? "720285" : "",
      alteInfo: cod === 33 ? "Showroom Autodel" : "",
    };
    // daca nu este Suceava, nu schimb
    if (data.locatieIntrare) {
      setValue("locatie.locatieIntrare", newLocationObject);
    } else {
      setValue("locatie.locatieIesire", newLocationObject);
    }
  };

  /**
   *
   * @returns
   */
  const hasAccessToken = () => {
    const firma_achizitie_id = vehicleContext.state.vehicle?.firma_achizitie_id;
    const companieAchizitie: any = companies?.filter(
      (comp: any) => comp.id === firma_achizitie_id
    );
    return !!companieAchizitie[0]?.access_token;
  };

  /**
   *
   * @param data
   */
  const handleSubmitNotificationToANAF = async (data: any) => {
    data.dateTransport.codOrgTransport = containsLetters(data.dateTransport.codOrgTransport) ? data.dateTransport.codOrgTransport?.substring(
      2
    ):data.dateTransport.codOrgTransport;
    if (data?.CUI) {
      const company: string | undefined = companies?.find(
        (company) => (company as any).CIF === data.CUI
      );
      if (company) {
        data.token = (company as any)?.access_token;
        data.CUI = containsLetters(data.CUI) ? data.CUI?.substring(2):data.CUI;
      }
    }
    data.vinAutovehicul = vehicleContext.state.vehicle?.numeCompletVin;
    data.bunuriTransportate[0].valoareLeiFaraTva = formatNumberToEuropean(
      data.bunuriTransportate[0].valoareLeiFaraTva
    );
    data.dateTransport.dataTransport = formatDate(
      data.dateTransport.dataTransport,
      anafFormatDate
    );
    data.documenteTransport.forEach((doc: any, index: number) => {
      data.documenteTransport[index].dataDocument = formatDate(
        data.documenteTransport[index].dataDocument,
        anafFormatDate
      );
    });
    // ADD NEW SHIPPINGS
    const formatedShippings = {
      name: data.dateTransport.denumireOrgTransport,
      CIF: data.dateTransport.codOrgTransport,
      nrVehicul: data.dateTransport.nrVehicul,
      nrRemorca: data.dateTransport.nrRemorca,
    };
    const url = `${urlEnum.shipping}${disableTostifyConfig}`;
    const newShipping: any = await postData(url, formatedShippings);
    // SEND TO ANAF
    postData(urlEnum.uploadNotification, data)
      .then((res: any) => {
        console.log("ANAF notification sent");
        // ------------------------------------------------------------------------------------------------------------
        const status = cacheContext.cache?.vehicleStatus?.find(
          (status: any) => status.nume === VehiclesStatus.inTransit
        );
        const localVehicleInfo = {
          ...vehicleContext.state.vehicle,
          indexes: vehicleContext.updateIndex(["0.1"], null),
        };
        const reserVeVehicle = {
          vehicle: localVehicleInfo,
          vehicle_id: vehicleContext.state.vehicle.id,
          status_id: status.id,
          shipping_id: newShipping.data.shipping.id,
          UIT: res.data.UIT,
          trace_id: res.data.trace_id,
          index_incarcare: res.data.index_incarcare,
          dateResponse: res.data.dateResponse,
        };

        const reserveVehicleUrl = `${urlEnum.reserveForVehicle}${disableTostifyConfig}`;
        postData(reserveVehicleUrl, reserVeVehicle).then((response) => {
          if (response) vehicleContext.updateVehicle(response?.data?.vehicle);
        });
        // ------

        //-----------------------------------------Add Notification to my database-------------------------------------------
        const addNotification = `${urlEnum.anafNotifications}${disableTostifyConfig}`;
        data.uit = res.data.UIT;
        data.bunuriTransportate = JSON.stringify(data.bunuriTransportate);
        data.documenteTransport = JSON.stringify(data.documenteTransport);
        data.partenerComercial = JSON.stringify(data.partenerComercial);
        data.dateTransport = JSON.stringify(data.dateTransport);
        data.codPtf = JSON.stringify(data.codPtf);
        data.locatie = JSON.stringify(data.locatie);

        postData(addNotification, data).then((response) => {
          if (response) console.log(response.data);
        });
        //-----------------------------------------End Add Notification to my database-------------------------------------------
        setOpenAnafNotificationModal(!openAnafNotificationModal);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   * Handles the submission of a notification to ANAF.
   *
   * This function takes the provided data, formats it according to ANAF requirements,
   * sends the notification to ANAF, and then updates the vehicle status and shipping information.
   *
   * @param {any} data - The data to be sent to ANAF.
   * @return {void}
   */
  const handleSubmitEditNotificationToANAF = async (data: any) => {
    if (containsLetters(data.dateTransport.codOrgTransport))
      data.dateTransport.codOrgTransport = data.dateTransport.codOrgTransport?.substring(
        2
      );
    if (data?.CUI) {
      const company: string | undefined = companies?.find(
        (company) => (company as any).CIF === data.CUI
      );
      if (company) {
        data.token = (company as any)?.access_token;
        data.CUI = data.CUI?.substring(2);
      }
    }
    data.bunuriTransportate[0].valoareLeiFaraTva = formatNumberToEuropean(
      data.bunuriTransportate[0].valoareLeiFaraTva
    );
    data.dateTransport.dataTransport = formatDate(
      data.dateTransport.dataTransport,
      anafFormatDate
    );
    data.documenteTransport.forEach((doc: any, index: number) => {
      data.documenteTransport[index].dataDocument = formatDate(
        data.documenteTransport[index].dataDocument,
        anafFormatDate
      );
    });
    // ADD NEW SHIPPINGS
    const formatedShippings = {
      name: data.dateTransport.denumireOrgTransport,
      CIF: data.dateTransport.codOrgTransport,
      nrVehicul: data.dateTransport.nrVehicul,
      nrRemorca: data.dateTransport.nrRemorca,
    };
    const url = `${urlEnum.shipping}`;
    const newShipping: any = await postData(url, formatedShippings);
    console.log(newShipping);
    // SEND TO ANAF
    postData(urlEnum.uploadNotification, data)
      .then((res: any) => {
        console.log("ANAF notification sent" + res);
        //-----------------------------------------Update Notification to my database-------------------------------------------
        const addNotification = `${urlEnum.anafNotifications}`;
        //data.uit = res.data.UIT;
        data.bunuriTransportate = JSON.stringify(data.bunuriTransportate);
        data.documenteTransport = JSON.stringify(data.documenteTransport);
        data.partenerComercial = JSON.stringify(data.partenerComercial);
        data.dateTransport = JSON.stringify(data.dateTransport);
        data.codPtf = JSON.stringify(data.codPtf);
        data.locatie = JSON.stringify(data.locatie);

        updateData(addNotification, data).then((response) => {
          if (response) console.log(response.data);
        });
        //-----------------------------------------End Update Notification to my database-------------------------------------------

        // we need to a
        setOpenEditAnafNotification(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onError = (data: any) => {
    // eslint-disable-next-line no-console
    const selectedValues = getValues();
    console.log(selectedValues);
    console.log(data); //E lasat aici pentru a vedeam campurile care nu sunt completate
  };

  return (
    <ProgressActionWrapper
      title={Vocabulary.shipping}
      status={vehicleContext?.state?.vehicle?.indexes?.includes("0.2")}
    >
      <Stepper className={styles.stepper} orientation="vertical">
        <Step active>
          <StepLabel>
            <Typography className={styles.stepperLabel}>
              {Vocabulary.bookingShipping}
            </Typography>
          </StepLabel>
          <StepContent>
            <ProgressAction index="0.1">
              <div>
                {openReserveDelete && (
                  <SubProgressInformation5_1Delete
                    open={openReserveDelete}
                    handleModalReserve={handleModalReserveDelete}
                  ></SubProgressInformation5_1Delete>
                )}
                {openReserve && (
                  <SubProgressInformation5_1
                    shippings={shippings}
                    refreshShippingList={() => getShippings()}
                    open={openReserve}
                    handleModalReserve={handleModalReserve}
                  />
                )}
                <div className={styles.stepContainer}>
                  <div style={{ margin: 15 }}>
                    {vehicleContext?.state?.vehicle?.vehicle_shipping_r
                      ?.length > 0 ? (
                      <>
                        <div style={{ display: "flex" }}>
                          <div className={styles.reserveBox}>
                            <Typography style={{ marginRight: 5 }}>
                              {Vocabulary.shippingRezervedFor}:
                            </Typography>
                            <Typography style={{ fontWeight: 800 }}>
                              {
                                shippings?.find((element: any) => {
                                  if (
                                    element.id ===
                                    +vehicleContext?.state?.vehicle
                                      ?.vehicle_shipping_r[
                                      vehicleContext?.state?.vehicle
                                        ?.vehicle_shipping_r.length - 1
                                    ]?.shipping_id
                                  ) {
                                    return element.name;
                                  }
                                  return null;
                                })?.["name"]
                              }
                            </Typography>
                            <Typography>
                              &nbsp;{Vocabulary.shippingAtTheDate}: &nbsp;
                            </Typography>
                            <Typography style={{ fontWeight: 800 }}>
                              {moment(
                                vehicleContext?.state?.vehicle
                                  ?.vehicle_shipping_r[
                                  vehicleContext?.state?.vehicle
                                    ?.vehicle_shipping_r.length - 1
                                ]?.created_at
                              ).format(europeanDate)}
                            </Typography>
                            {vehicleContext?.state?.vehicle
                              ?.vehicle_shipping_r &&
                            vehicleContext?.state?.vehicle
                              ?.vehicle_shipping_r[0].UIT ? (
                              <>
                                <Typography>
                                  &nbsp;{Vocabulary.uitValue}: &nbsp;
                                </Typography>
                                <Typography style={{ fontWeight: 800 }}>
                                  {vehicleContext?.state?.vehicle
                                    ?.vehicle_shipping_r &&
                                    vehicleContext?.state?.vehicle?.vehicle_shipping_r[0].UIT.match(
                                      /.{1,4}/g
                                    ).join("-")}
                                </Typography>
                              </>
                            ) : null}

                            {vehicleContext?.state?.vehicle?.contractData
                              ?.observatii ? (
                              <Typography style={{ marginLeft: 10 }}>
                                {Vocabulary.observations}:{" "}
                                {
                                  vehicleContext?.state?.vehicle?.contractData
                                    ?.observatii
                                }
                              </Typography>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      Vocabulary.noShipping
                    )}
                  </div>
                  {vehicleContext?.state?.vehicle?.vehicle_shipping_r
                    ?.length === 0 &&
                    hasAccessToken() && (
                      <Button
                        variant="contained"
                        style={{
                          color: "#fff",
                          padding: 15,
                          margin: 10,
                          borderRadius: 7,
                          cursor: "pointer",
                        }}
                        onClick={handleModalSendNotificationToANAF}
                      >
                        <LocalShippingIcon />
                      </Button>
                    )}
                  {vehicleContext?.state?.vehicle?.vehicle_shipping_r &&
                    vehicleContext?.state?.vehicle?.vehicle_shipping_r[0]
                      ?.UIT &&
                    hasAccessToken() && (
                      <Button
                        variant="contained"
                        style={{
                          color: "#fff",
                          padding: 15,
                          margin: 10,
                          borderRadius: 7,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          const selectedNotificationData: any = {
                            dateTransport: {},
                            bunuriTransportate: {},
                            partenerComercial: {},
                          };
                          selectedNotificationData.CUI = (companies?.find(
                            (comp: any) =>
                              comp.id ===
                              vehicleContext.state.vehicle?.firma_achizitie_id
                          ) as any)?.CIF;
                          selectedNotificationData.uit =
                            vehicleContext?.state?.vehicle?.vehicle_shipping_r[0].UIT;

                          setNotificationForUpdate(selectedNotificationData);
                          setOpenEditAnafNotification(true);
                        }}
                      >
                        <EditIcon />
                      </Button>
                    )}
                </div>

                {openEditAnafNotification && (
                  <AnafNotification
                    openAnafNotificationModal={openEditAnafNotification}
                    setOpenAnafNotificationModal={() =>
                      setOpenEditAnafNotification(false)
                    }
                    handleSubmitNotificationToANAF={(data: any) => {
                      handleSubmitEditNotificationToANAF(data);
                    }}
                    selectedData={notificationForUpdate}
                    anafNotificationTitle={Vocabulary.editAnafNotificationTitle}
                  />
                )}

                {/*-------------------ANAF NOTIFICATION--------------------------*/}
                <GenericModal
                  open={openAnafNotificationModal}
                  onClose={() => setOpenAnafNotificationModal(false)}
                  maxWidth={1200}
                  title={`${Vocabulary.anafNotificationTitle}`}
                >
                  <p style={{ textAlign: "center", fontSize: 20 }}>
                    {Vocabulary.anafNotificationMessage}
                  </p>
                  <form
                    onSubmit={handleSubmit(
                      handleSubmitNotificationToANAF,
                      onError
                    )}
                    method="get"
                    id="sendNotificationToANAF"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <Controller
                      name="CUI"
                      control={control}
                      defaultValue={
                        (companies?.find(
                          (comp: any) =>
                            comp.id ===
                            vehicleContext.state.vehicle?.firma_achizitie_id
                        ) as any)?.CIF
                      }
                      render={({
                        field: { ref, value, onChange, ...field },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          freeSolo={false}
                          style={{
                            width: isMobile() ? "100%" : 320,
                            marginBottom: 15,
                          }}
                          {...field}
                          size="small"
                          disableClearable
                          disablePortal
                          getOptionLabel={(option: any) => option.nume_firma}
                          id="CUI"
                          // onChange={(event, value: any) =>
                          //   field.onChange(value.CIF)
                          // }
                          onChange={(event, value: any) => {
                            onChange(value?.CIF || ""); // Update form value to the selected option's cod
                          }}
                          value={
                            companies?.find(
                              (option: any) => option.CIF === value
                            ) || undefined
                          }
                          options={companies}
                          getOptionDisabled={(option) =>
                            option?.access_token === null
                          }
                          renderInput={(params) => (
                            <TextField
                              error={!!error}
                              helperText={error?.message}
                              label="CUI"
                              name="CUI"
                              type="search"
                              inputRef={ref}
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                    <Controller
                      name="tipOperatie"
                      control={control}
                      defaultValue={tipOperatiuni[0].id}
                      render={({
                        field: { ref, value, onChange, ...field },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          freeSolo={false}
                          style={{
                            width: isMobile() ? "100%" : 320,
                            marginBottom: 15,
                          }}
                          {...field}
                          size="small"
                          disableClearable
                          disablePortal
                          getOptionLabel={(option: any) => option.cod}
                          id="tipOperatie"
                          onChange={(event, value: any) => {
                            onChange(value?.id || ""); // Update form value to the selected option's cod
                          }}
                          value={
                            tipOperatiuni?.find(
                              (option: any) => option.id === value
                            ) || undefined
                          }
                          options={tipOperatiuni}
                          renderInput={(params) => (
                            <TextField
                              error={!!error}
                              helperText={error?.message}
                              label="Tip operatiune"
                              name="tipOperatie"
                              type="search"
                              inputRef={ref}
                              {...params}
                            />
                          )}
                        />
                      )}
                    />
                    <PartenerComercial
                      control={control}
                      taraOrigine={
                        vehicleContext.state.vehicle?.tara_origine_r?.nume
                      }
                    />
                    <DateTransport
                      control={control}
                      setDateTransportor={setDateTransportor}
                      shippings={shippings}
                      hasCodOrgOrNameTransportor={watch("dateTransport")}
                      nrVehicule={
                        (getCompanyBasedOnCUIorName(
                          (getValues("dateTransport") as any)
                            ?.denumireOrgTransport
                        ) as any)?.nrVehicul
                      }
                      nrRemorca={
                        (getCompanyBasedOnCUIorName(
                          (getValues("dateTransport") as any)
                            ?.denumireOrgTransport
                        ) as any)?.nrRemorca
                      }
                    />
                    <LocDeStartFinalTraseu
                      control={control}
                      locatieIntrare={watch("tipIntrare")}
                      locatieIesire={watch("tipIesire")}
                      onChangeADRJudet={onChangeADRJudet}
                    />
                    <BunuriTransport
                      control={control}
                      bunuriTransportate={bunuriTransportate}
                      unitateMasura={unitateMasura}
                      pretAchizitie={
                        vehicleContext.state.vehicle?.pret_achizitie_ron
                      }
                      denumireBunTransportat={
                        vehicleContext.state.vehicle?.numeCompletVin
                      }
                    />
                    <DocumenteTransport
                      control={control}
                      deleteDocument={deleteDocument}
                      addDocument={addDocument}
                      documents={getDocumenteTransport}
                    />
                    <Grid
                      container
                      spacing={isMobile() ? 1 : 4}
                      style={{ padding: 10 }}
                    >
                      <Grid item md={6} xs={12}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={handleModalSendNotificationToANAF}
                          fullWidth
                        >
                          {Vocabulary.no}
                        </Button>
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <Button
                          variant="contained"
                          fullWidth
                          style={{ color: "white" }}
                          type="submit"
                          form="sendNotificationToANAF"
                        >
                          {Vocabulary.yes}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </GenericModal>
              </div>
              {/* //BUTON DE REZERVA TRANSPORT */}
            </ProgressAction>
          </StepContent>
        </Step>
        <Step
          active={
            vehicleContext?.state?.vehicle?.vehicle_shipping_r?.length > 0
          }
        >
          <StepLabel>
            <Typography className={styles.stepperLabel}>
              {Vocabulary.completeShipping}
            </Typography>
          </StepLabel>
          <StepContent>
            <ProgressAction index="0.2">
              <div>
                {openReserve5_2 && (
                  <SubProgressInformation5_2
                    shippings={shippings}
                    refreshShippingList={() => getShippings()}
                    open={openReserve5_2}
                    handleModalReserve={handleModalReserve5_2}
                  />
                )}
                <div className={styles.stepContainer}>
                  <div style={{ margin: 15 }}>
                    {vehicleContext?.state?.vehicle?.indexes?.includes(
                      "0.2"
                    ) ? (
                      <>
                        <div style={{ display: "flex" }}>
                          <div className={styles.reserveBox}>
                            <Typography style={{ marginRight: 5 }}>
                              {Vocabulary.pickupDate}:
                            </Typography>
                            <Typography
                              style={{ marginRight: 5, fontWeight: 600 }}
                            >
                              {vehicleContext?.state?.vehicle
                                ?.vehicle_shipping_r &&
                                vehicleContext?.state?.vehicle
                                  ?.vehicle_shipping_r[0]?.pickupDate}
                            </Typography>
                            <Typography>
                              &nbsp;{Vocabulary.estimatedArrivalDate}: &nbsp;
                            </Typography>
                            <Typography
                              style={{ marginRight: 5, fontWeight: 600 }}
                            >
                              {vehicleContext?.state?.vehicle
                                ?.vehicle_shipping_r &&
                                vehicleContext?.state?.vehicle
                                  ?.vehicle_shipping_r[0]?.estimatedArrivalDate}
                            </Typography>

                            {vehicleContext?.state?.vehicle?.contractData
                              ?.observatii ? (
                              <Typography style={{ marginLeft: 10 }}>
                                {Vocabulary.observations}:{" "}
                                {
                                  vehicleContext?.state?.vehicle?.contractData
                                    ?.observatii
                                }
                              </Typography>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      Vocabulary.noShipping5_2
                    )}
                  </div>

                  <Button
                    style={{
                      display: "flex",
                      float: "right",
                      color: "#fff",
                      margin: 15,
                    }}
                    variant="contained"
                    onClick={handleModalReserve5_2}
                  >
                    {Vocabulary.edit}
                  </Button>
                </div>
              </div>
              {/* //BUTON DE REZERVA TRANSPORT */}
            </ProgressAction>
          </StepContent>
        </Step>
      </Stepper>
    </ProgressActionWrapper>
  );
}
