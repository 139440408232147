/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Pusher from "pusher-js";
import { getData } from "../Services/getData";
import { urlEnum } from "./urlEnum";
import { VehicleModel } from "../Models/VehicleModel";
import { postData } from "../Services/postData";

/**
 *
 * @param response
 * @param fileName
 * @param type
 */
export async function download(response: Blob, fileName: string, type: string) {
  // Create a new blob from the response
  const blob = new Blob([response], { type });

  // Create a URL for the blob
  const url = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");
  link.download = fileName;  // Set the file name for the download
  link.href = url;           // Set the href to the blob URL

  // Append the link to the body (not always necessary, but good for cross-browser support)
  document.body.appendChild(link);

  // Trigger the download by simulating a click
  link.click();

  // Clean up: remove the link from the document and revoke the object URL
  document.body.removeChild(link);
  URL.revokeObjectURL(url); // Free up memory
}

/**
 *
 * @param path
 * @returns
 */
export function getFileExtensionByFileType(path: string) {
  const splitName = path.split(".").reverse();
  const extension = splitName.length ? splitName[0] : null;
  return extension;
}

/**
 *
 * @param path
 * @param height
 * @returns
 */
export function getIconByFileFileType(path: string, height: number) {
  const extension = getFileExtensionByFileType(path);
  switch (extension) {
    case "pdf":
      return (
        <img
          src="/pdf.png"
          alt="No file"
          height={height}
          style={{ position: "relative", top: 5, paddingRight: 5 }}
        />
      );

    case "doc":
    case "docx":
      return (
        <img
          src="/docx.png"
          alt="No file"
          height={height}
          style={{ position: "relative", top: 5, paddingRight: 5 }}
        />
      );
    case "xls":
    case "xlsx":
      return (
        <img
          src="/xls.png"
          alt="No file"
          height={height}
          style={{ position: "relative", top: 5, paddingRight: 5 }}
        />
      );
    case "jpg":
    case "jpeg":
    case "png":
    case "webp":
      return (
        <img
          src={`/api/${path}`}
          alt="No file"
          height={height}
          style={{ position: "relative", top: 5, paddingRight: 5 }}
        />
      );
    default:
      return null;
  }
}

/**
 *
 * @param array
 * @param item
 * @param id
 * @returns
 */
export function addOrUpdateArray(array: any[], item: any, id: string) {
  const index = array.findIndex((i) => i[id] === item[id]);
  if (index === -1) {
    array.push(item);
  } else {
    array[index] = item;
  }
  return array;
}

/**
 *
 * @returns
 */
export async function getBnrEuroRate() {
  return new Promise((resolve, reject) => {
    const bnrUrl = urlEnum.bnrExchangeRates;
    getData(bnrUrl)
      .then((response) => {
        // const parser = new DOMParser();
        // const xmlDoc = parser.parseFromString(response.data, "text/xml");
        // const bnrEuroRate = parseFloat(
        //   xmlDoc.querySelector("Rate[currency='EUR']")?.textContent || ""
        // );
        // resolve(bnrEuroRate);
        resolve(response.data);
      })
      .catch(() => {
        reject();
      });
  });
}

/**
 *
 * @param file
 * @returns
 */
export function resizeImage(file: any) {
  const promise = new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (readerEvent) {
        const image = new Image();
        image.src = readerEvent.target?.result as string;
        image.onload = function (imageEvent) {
          const imageFile = resize(image, file.name);
          resolve(imageFile);
        };
        image.onerror = function (err) {
          reject(err);
        };
      };
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
  return promise;
}

/**
 *
 * @param image
 * @param fileName
 * @returns
 */
function resize(image: any, fileName: any) {
  const canvas = document.createElement("canvas");
  let { width } = image,
    { height } = image;
  const maxWidth = width > 200 ? 200 : width;
  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }
  } else {
    if (height > maxWidth) {
      width *= maxWidth / height;
      height = maxWidth;
    }
  }
  canvas.width = width;
  canvas.height = height;
  canvas?.getContext("2d")?.drawImage(image, 0, 0, width, height);
  const dataUrl = canvas.toDataURL("image/png");
  const resizedImage = dataURLToBlob(dataUrl);
  return blobToFile(resizedImage, fileName);
}

/**
 *
 * @param dataURL
 * @returns
 */
export function dataURLToBlob(dataURL: any) {
  const BASE64_MARKER = ";base64,";

  let parts = dataURL.split(BASE64_MARKER);
  let contentType = parts[0].split(":")[1];
  let raw = window.atob(parts[1]);
  const rawLength = raw.length;

  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(",");
    contentType = parts[0].split(":")[1];
    raw = parts[1];
    return new Blob([raw], { type: contentType });
  }
  const uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  const blob = new Blob([uInt8Array], { type: contentType });
  return blob;
}

/**
 *
 * @param {*} ab
 * @param {*} fileName
 * @returns
 */
function blobToFile(ab: any, fileName: any) {
  const f = new File([ab], fileName, {
    type: "image/jpeg",
    lastModified: new Date().getTime(),
  });
  return f;
}

/**
 *
 */
export const PaymentMethods = [
  {
    id: 1,
    nume: "Numerar",
  },
  {
    id: 2,
    nume: "Transfer bancar",
  },
];

/**
 *
 */
export const PurchaseTypes = {
  withoutAdvance: "withoutAdvance",
  totalFunding: "totalFunding",
  withInvoice: "withInvoice",
  totalIndexes: "totalIndexes",
};

/**
 *
 */
export const PurchaseTypesArray = {
  totalFunding: [
    "0.1",
    "0.2",
    "1.1",
    "2.1",
    "3.1",
    "3.2",
    "4.1",
    "4.2",
    "4.3",
    "4.4",
  ],
  totalIndexes: [
    "0.1",
    "0.2",
    "1.1",
    "2.1",
    "3.1",
    "3.2",
    "3.3",
    "3.4",
    "4.1",
    "4.2",
    "4.3",
    "4.4",
  ],
  withInvoice: [
    "0.1",
    "0.2",
    "1.1",
    "2.1",
    "3.1",
    "3.3",
    "3.4",
    "4.1",
    "4.2",
    "4.3",
    "4.4",
  ],
  withoutAdvance: [
    "0.1",
    "0.2",
    "1.1",
    "2.1",
    "3.1",
    "4.1",
    "4.2",
    "4.3",
    "4.4",
  ],
} as any;

export function verifySellTypeOrReserve(item: string) {
  switch (item) {
    case "withInvoice":
      return PurchaseTypes.withInvoice;
    case "withoutAdvance":
      return PurchaseTypes.withoutAdvance;
    case "totalFunding":
      return PurchaseTypes.totalFunding;
    default:
      return PurchaseTypes.totalIndexes;
  }
}

export const GenericTemplatesType = ["Email", "Descriere vehicul"];
/**
 *
 * @param index
 * @param purchaseType
 * @returns next step index or null
 */
export function validateNextStep(index: string, purchaseType: string) {
  const currentPosition = PurchaseTypesArray[purchaseType].indexOf(index);
  if (currentPosition === -1) return null;
  if (currentPosition + 1 === PurchaseTypesArray[purchaseType].length)
    return PurchaseTypesArray[purchaseType][currentPosition];
  return PurchaseTypesArray[purchaseType][currentPosition + 1];
}

export function activeStep(
  index: string,
  indexes: Array<string>,
  purchaseType: string
) {
  if (indexes) {
    if (PurchaseTypesArray[purchaseType].indexOf(index) < 0) return false;
    if (
      parseFloat(index) <=
      parseFloat(validateNextStep(indexes[indexes.length - 1], purchaseType))
    ) {
      return true;
    }
  }
  return false;
}

/**
 *
 * @param index
 * @param indexes
 * @returns
 */
export function disableStep(index: string, indexes: Array<string>) {
  if (indexes) {
    if (parseFloat(index) <= parseFloat(indexes[indexes.length - 1])) {
      return true;
    }
  }
  return false;
}

/**
 *
 * @returns
 */
export default function createConnectionForWebsocket() {
  const pusher = new Pusher(process.env.REACT_APP_PUSHER_API_KEY || "12345", {
    auth: {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        Accept: "application/json",
      },
    },
    httpHost: window.location.hostname,
    enabledTransports: window.location.protocol.includes("https")
      ? ["ws", "wss"]
      : ["ws"],
    cluster: process.env.REACT_APP_PUSHER_CLUSTER || "eu",
    wsHost: window.location.hostname,
    wsPort: 8443,
    wssPort: 8443,
    disableStats: false,
    forceTLS: false,
  });
  return pusher;
}

/**
 * Read a cookie
 * @param {String} name
 */
export const readCookie = (name: string) => {
  const n = `${name}=`;
  const index = document.cookie.indexOf(n);
  if (index < 0) {
    return "";
  }
  let cookieValue = document.cookie.substring(index + n.length);
  const end = cookieValue.indexOf(";");
  if (end >= 0) {
    cookieValue = cookieValue.substring(0, end);
  }
  return cookieValue;
};

export const NUMBER_OF_DECIMALS = 3;

/**
 *
 * @param model Main model (object)
 * @param completedData The object that is completed and can have undefined props
 * @returns New Filtered Object (Return is present to be easier to understand what the function will return)
 */
export const mergeObjects = (model: any, completedData: any) => {
  for (const idx in completedData) {
    if (completedData[idx] !== undefined) model[idx] = completedData[idx];
  }
  return model;
};

/**
 *
 * @param arrayName
 * @param id
 * @returns
 */
export function getItemByIdFromArray(
  arrayName: Array<any>,
  id: number | string
) {
  const index = arrayName.findIndex((i) => i.id === id);
  if (index === -1) {
    return null;
  }
  return arrayName[index];
}

/**
 *
 * @param arrayName
 * @param id
 * @returns
 */
export function getNameFromArrayById(
  arrayName: Array<any>,
  id: number | null | undefined
) {
  return arrayName.find((item: any) => item.id === id)?.nume || "";
}

/**
 *
 * @param text
 * @param vehicle
 * @param cache
 * @returns
 */
export function replaceMacro(text: string, vehicle: VehicleModel, cache: any) {
  text = text.replaceAll(
    "{{vehicleBrand}}",
    getNameFromArrayById(cache.brands, vehicle.brand_id)
  );
  text = text.replaceAll(
    "{{vehicleModel}}",
    getNameFromArrayById(cache.models, vehicle.model_id)
  );
  text = text.replaceAll(
    "{{vehicleVariant}}",
    vehicle.variant ? vehicle.variant : ""
  );
  text = text.replaceAll(
    "{{color}}",
    `${getNameFromArrayById(cache.colors, vehicle.culoare_id)} ${getNameFromArrayById(cache.colorsOptions, vehicle.optiuni_culoare_id)}`
  );
  text = text.replaceAll(
    "{{km}}",
    vehicle.kilometri ? vehicle.kilometri.toString() : ""
  );
  text = text.replaceAll(
    "{{fabricationYear}}",
    vehicle.an_fabricatie ? vehicle.an_fabricatie.toString() : ""
  );
  text = text.replaceAll(
    "{{engineCapacity}}",
    vehicle.capacitate_cilindrica
      ? vehicle.capacitate_cilindrica.toString()
      : ""
  );
  text = text.replaceAll(
    "{{enginePower}}",
    vehicle.putere_cp ? vehicle.putere_cp.toString() : ""
  );
  text = text.replaceAll(
    "{{engineFuel}}",
    getNameFromArrayById(cache.fuelTypes, vehicle.combustibil_id)
  );
  text = text.replaceAll(
    "{{engineGearbox}}",
    getNameFromArrayById(cache.gearboxes, vehicle.cutie_viteze_id)
  );
  text = text.replaceAll(
    "{{engineDrive}}",
    getNameFromArrayById(cache.tractionTypes, vehicle.tractiune_id)
  );
  text = text.replaceAll(
    "{{pollutionNorm}}",
    getNameFromArrayById(cache.pollutionNorms, vehicle.norma_poluare_id)
  );
  let facilities = "";
  if (vehicle.dotari) {
    vehicle.dotari.forEach((facility) => {
      facilities += `<p>${facility.nume}</p>`;
    });
  }
  text = text.replaceAll("{{facilities}}", facilities);
  return text;
}

/**
 *
 * @param array //Se face verificare numai dupa cheia "name"- Functie TREBUIE implementata pentru eliminarea obiectelor duplicate din MainSlider si VehicleOptions
 */
export function removeDuplicatesFromArrayOfObjects(array: object[]) {
  const uniqueIds: any = [];
  array?.filter((element: any) => {
    const isDuplicate = uniqueIds.includes(element?.name);
    if (!isDuplicate) {
      uniqueIds.push(element?.name);
      return true;
    }
    return false;
  });
  return uniqueIds;
}

//  /**
//   *
//   * @param number
//   * @returns
//   */
//  export const replaceDotWithComma = (number: any) => {
//   const newNumber = number?.toString();
//    return newNumber && newNumber.replace(/\./g, ",");
//  };
export function isMobile() {
  const userAgent = navigator.userAgent;
  const mobileDevices = [
    /android/i,
    /webos/i,
    /iphone/i,
    /ipad/i,
    /ipod/i,
    /blackberry/i,
    /windows phone/i,
  ];
  return mobileDevices.some((device) => userAgent.match(device));
}

/**
 * Get info from ANAF
 */
export async function getCompanyInfo(CUI: string, countryCode: string) {
  const companyInfo = await postData("/api/getCompanyByCUI", {
    url: `https://ec.europa.eu/taxation_customs/vies/rest-api/ms/${countryCode}/vat/${CUI}`,
  });
  return companyInfo;
}

export function formatNumberToEuropean(number: string) {
  // Remove any non-numeric characters except the decimal point
  const cleanedInput = number.replace(/[^0-9.]/g, "");

  // Split the number into integer and decimal parts
  let [integerPart, decimalPart] = cleanedInput.split(".");

  // Ensure the integer part is no more than 12 digits
  if (integerPart.length > 12) {
    integerPart = integerPart.slice(0, 12);
  }

  // Ensure the decimal part is no more than 2 digits
  if (decimalPart && decimalPart.length > 2) {
    decimalPart = decimalPart.slice(0, 2);
  }

  // If the decimal part is empty or undefined, return only the integer part
  if (!decimalPart) {
    return integerPart;
  }

  // Return the formatted number
  return `${integerPart}.${decimalPart}`;
}

export function exportTable(
  url: string,
  filters: any,
  fileName: string,
  columns: []
) {
  const element = document.getElementById("loading");
  element?.classList.remove("loadingDisabled");
  element?.classList.add("loading");
  const token = localStorage.getItem("access_token");
  if (url)
    fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ filters, columns }),
    })
      .then((response) => response.blob())
      .then((response) => {
        download(
          response,
          fileName,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );

        //disable loading
        element?.classList.remove("loading");
        element?.classList.add("loadingDisabled");
      });
}

/**
 * Checks if the given string is empty or consists only of whitespace.
 *
 * @param content - The string to check.
 * @returns - Returns true if the string is empty or only contains whitespace, otherwise false
 */
export function isEmpty(content: string) {
  return !content || (content && content == "");
}

export const disableTostifyConfig = "?disableTostify";

export function containsLetters(str: string) {
  return /[a-zA-Z]/.test(str);
}
