import { Vocabulary } from "./Vocabulary";

export const tipFisier = [
  {
    id: 1,
    nume: "Facturi",
  },
  {
    id: 2,
    nume: "Devize",
  },
  {
    id: 2,
    nume: "Acte",
  },
];

export const tipStatus = [
  {
    id: 0,
    nume: "Pending",
  },
  {
    id: 1,
    nume: "Platit",
  },
];

export const tipStatusVehicule = [
  {
    id: 0,
    nume: "Vehicul",
  },
  {
    id: 1,
    nume: "Comanda",
  },
  {
    id: 2,
    nume: "MasinaComanda",
  },
  {
    id: 3,
    nume: "Template",
  },
  {
    id: 4,
    nume: "MesajeClienti",
  },
];

export const logsAction = [
  {
    id: "created",
    nume: Vocabulary.created,
  },
  {
    id: "updated",
    nume: Vocabulary.updated,
  },
  {
    id: "deleted",
    nume: Vocabulary.deleted,
  },
];

export const transportAction = [
  {
    id: "created",
    nume: Vocabulary.created,
  },
  {
    id: "updated",
    nume: Vocabulary.updated,
  },
  {
    id: "deleted",
    nume: Vocabulary.deleted,
  },
];

export const clientDocType = [
  {
    id: 0,
    nume: "GDPR",
  },
  {
    id: 1,
    nume: "Antecontract",
  },
];

export const VehicleFileType = {
  providerInvoice: "Factura furnizor",
  providerOP: "OP furnizor",
  vehicleInvoice: "Factura vehicul",
  clientInvoice: "Factura client",
  contract: "Contract",
  contractDraft: "Contract draft",
  contractAdvance: "Contract avans",
  contractAdvanceDraft: "Contract avans draft",
  proforma: "Proforma avans",
  amendment: "Act aditional",
  proformaLeasing: "Proforma leasing",
  proformaFinancing: "Proforma finantare",
  invoice: "Factura avans",
  OP: "OP avans",
};
export const ProformaVehicleFileType = [
  "Proforma leasing",
  "Proforma finantare",
  "Proforma avans",
];

export const ProformaVehicle3_3 = ["Factura avans", "OP avans"];

export const VehiclesStatus = {
  sold: "Vandut",
  reserved: "Rezervat",
  received: "Receptionat",
  advancePaid: "Avans achitat",
  contractToSign: "Contract de semnat",
  inTransit: "In tranzit",
  paid: "Achitat",
  unpaid: "Neachitat",
  readyForSale: "Pregatit pentru vanzare",
  canceled: "Anulata",
};

export const ClientMessagesStatus = {
  unread: "Necitit",
  canceled: "Anulat",
};

export const CarOrderStatus = {
  unread: "Necitita",
  canceled: "Anulata",
};

export const PurchaseTypes = {
  buyBack: "Buy Back",
  intemediated: "Intermediere",
};
export const ReportsTab = {
  statistically: "statistically",
  vehicles: "vehicles",
  brands: "brands",
  models: "models",
  administrativeLocations: "administrativeLocations",
  shipping: "shipping",
};

export const TemplatesTab = {
  templates: "templates",
  generateDocuments: "generateDocuments",
};

export const CarOrdersTab = {
  allocated: "assigned",
  unallocated: "unassigned",
  canceled: "canceled",
};

export const VehiclesTab = {
  unsold: "unsold",
  sold: "sold",
  partialVehicles: "partialVehicles",
  reserved: "reserved",
  unpaid: "unpaid",
  paid: "paid",
  advancePaid: "advancePaid",
  contractToSign: "contractToSign",
  contractSigned: "contractSigned",
  onDemandVehicles: "onDemandVehicles",
};

export const ClientMessagesTab = {
  unassigned: "unsold",
  assigned: "assigned",
  canceled: "canceled",
  all: "all",
};

export const VehicleDashboardTableType = {
  detailing: "data_intrare_detailing",
  painting: "data_intrare_vopsitorie",
  service: "data_intrare_service",
  files: "files",
  costs: "costs",
  guarantees: "guarantees",
};

export const clientMessagesType = {
  contactMessage: "contactMessage",
  financingRequest: "financingRequest",
};
